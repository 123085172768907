import styled from 'styled-components'
import Image from '../UI/Image/Image'
import Text from '../UI/Text/Text'

export const StyledContact = styled.div`
  padding: 50px 0px;
`

export const Headline = styled(Text)`
  font-size: ${({ theme }) => theme.size.lg};
  font-family: 'bold';
  margin-top: 50px;
  margin-bottom: 25px;
`

export const CustomText = styled.div`
  margin: 25px 0;
  a {
    color: ${({ theme }) => theme.color.font.gold};
    text-decoration: none;
    &:hover {
      font-weight: bold;
    }
  }
`

export const Highlight = styled.div`
  font-family: 'bold';
  margin-top: 50px;
`

export const Split = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`

export const ImageContainer = styled.div`
  width: 30%;
  text-align: center;
`

export const CustomImage = styled(Image)`
  max-height: 100%;
  max-width: 300px;
`
