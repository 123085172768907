import { StyledPurchase, Headline, Strong, PointHeadline, CustomText, CTAWrapper, PointImage, ImageSplit } from './Purchase.Styled'
import Layout from '../UI/Layout/Layout'
import Container from '../UI/Container/Container'
import Hero from '../UI/Hero/Hero'
import heroImage from '../../assets/image/purchase/hero.jpg'
import goldImage from '../../assets/image/purchase/gold.jpg'
import shipmentImage from '../../assets/image/purchase/shipment.jpg'
import diamondsImage from '../../assets/image/purchase/diamonds.jpg'
import toothGoldImage from '../../assets/image/purchase/tooth-gold.png'
import goldCointsImage from '../../assets/image/purchase/gold-coins.jpeg'
import silverCoinsImage from '../../assets/image/purchase/silver-coins.jpg'
import silverDishesImage from '../../assets/image/purchase/silver-dishes.jpg'
import goldJewelryImage from '../../assets/image/purchase/gold-jewelry.jpg'
import zinnImage from '../../assets/image/purchase/zinn.png'
import PurchaseCTA from '../PurchaseCTA/PurchaseCTA'

const Purchase = () => {
  return (
    <Layout>
      <Hero img={heroImage}>
        <CTAWrapper>
          <PurchaseCTA />
        </CTAWrapper>
      </Hero>
      <StyledPurchase>
        <Container>
          <Headline>Wir kaufen Wertvolles. Ganz einfach. Ganz leicht.</Headline>
          <CustomText>
            Wir kaufen jede Form von Goldschmuck an, wie Ketten, Ringe, Anhänger, Armbänder, Ohrringe, Uhren und vieles mehr. Wenn Sie sich unsicher
            sind, ob es sich um echten Goldschmuck handelt, bieten wir Ihnen zwei Möglichkeiten. Kommen Sie zu uns oder schicken Sie uns Ihre Schätze.
            Wir prüfen und bewerten diese gerne für Sie.
          </CustomText>
          <Headline>Damit Ihre Wertgegenstände sicher bei uns ankommen!</Headline>
          <Headline>So geht´s:</Headline>
          <PointImage src={shipmentImage} />
          <PointHeadline>1. Edelmetallwert bis 500 €. Zusendung mit DHL oder Paketdienst:</PointHeadline>

          <CustomText>
            Sie bringen Ihre Wertsachen zu uns. Wir freuen uns, Sie persönlich in unserem Geschäft am{' '}
            <Strong>LUITPOLD-LEUSSER-PLATZ 1 in 86825 BAD WÖRISHOFEN</Strong> zu begrüßen.
          </CustomText>
          <CustomText>
            Sie sind nicht mobil? Rufen Sie uns an unter <a href="tel: 08247 9985208">08247 9985208</a> oder{' '}
            <a href="tel:0151 62664214">0151 62664214</a>. Dann vereinbaren wir gerne einen Termin für einen Besuch bei Ihnen zu Hause.
          </CustomText>
          <PointHeadline>2. Per sicherem Paketversand an BAYERN-GOLD24:</PointHeadline>
          <CustomText>
            Bitte wählen Sie für den Versand einen stabilen Karton. Scharfkantige Gegenstände polstern Sie mit einem Tuch oder Stoff. Kleine Teile
            fixieren Sie mit Klebeband. Die Gegenstände sollten sich im Karton nicht bewegen können. Der Inhalt sollte von außen nicht ertastbar oder
            identifizierbar sein. Verkleben Sie das Paket rundum, damit niemand in das Paket hineingreifen kann.
          </CustomText>
          <CustomText>
            Legen Sie Ihrer Sendung bitte das ausgefüllte{' '}
            <a href="https://www.bayern-gold24.de/custom-static-files/Begleitschreiben.pdf" target="_blank" rel="noreferrer">
              Begleitschreiben
            </a>{' '}
            bei.
            <br />
            Versenden Sie Ihr Paket über DHL oder Paketdienst{' '}
            <Strong>
              <u>mit Paketschein.</u>
            </Strong>{' '}
            Damit sind Ihre Wertsachen bis 500 € versichert.
          </CustomText>
          <CustomText>
            <div>BAYERN-GOLD24</div>
            <div>LUITPOLD-LEUSSER-PLATZ 1</div>
            <div>D 86825 BAD WÖRISHOFEN</div>
          </CustomText>
          <PointHeadline>3. Bei Edelmetallwert über 500 €:</PointHeadline>
          <CustomText>
            <div>
              Wir beauftragen UPS, um bei Ihnen nach Terminabsprache das Material versichert abzuholen. Bei hohen Werten lassen wir Ihre Schätze
              deutschlandweit durch eine Werttransportfirma bei Ihnen kostenfrei abholen (Ausland auf Anfrage).
            </div>
            <div>
              Wünschen Sie bei geringeren Materialwerten (Vergütungswert unter 500,- Euro) eine Werttransportabholung, so berechnen wir diese zum
              Selbstkostenpreis von 19,- Euro pro Abholung.
            </div>
          </CustomText>
          <Headline>Was wir kaufen:</Headline>
          <PointHeadline>1. Schmuckgold</PointHeadline>
          <PointImage src={goldJewelryImage} />
          <CustomText>Was tun mit altem oder aus der Mode geratenem Goldschmuck aus Eigenbesitz oder einer Erbschaft?</CustomText>
          <CustomText>
            Sie sind unsicher wegen Zusammensetzung oder Goldanteil der Stücke? Dafür sind wir mit unserer Expertise Ihr vertrauenswürdiger
            Ansprechpartner. Wir analysieren Ihre Schätze fachgerecht und zahlen einen fairen, tagesaktuellen Preis für den enthaltenen Goldanteil.
            Das Angebot gilt ebenso für Silber- oder Platinschmuck.
          </CustomText>
          <CustomText>
            <Strong>Welche Anforderungen werden an den Goldschmuck gestellt?</Strong>
          </CustomText>
          <CustomText>
            Bringen Sie Ihren Schmuck gerne zu uns so wie er ist. Ob die Stücke beschädigt oder zu Bruch gegangen sind (Bruchgold), nicht dem
            Zeitgeist entsprechen, spielt keine Rolle. Bringen Sie uns gerne vom Goldschmied eingeschmolzene Edelmetalle oder mit Steinen besetzte
            Schmuckstücke.
          </CustomText>
          <PointHeadline>2. Diamanten</PointHeadline>
          <PointImage src={diamondsImage} />
          <CustomText>
            Jeder Diamant ist wertvoll. Es handelt sich um eine knappe Ressource. Zudem ist die Nachfrage nach Diamatschmuck und Anlagediamanten nach
            wie vor groß. Wir prüfen und bewerten diese sorgfältig für einen fairen Handel.
          </CustomText>
          <CustomText>
            <div>Bieten Sie uns gerne jede Art von Diamanten an, unabhängig von Größe, Zertifizierung, gebrochen oder unversehrt.</div>
            <div>Wollen Sie sich von Goldbarren oder Gold- oder Silbermünzen trennen?</div>
          </CustomText>
          <PointHeadline>3. Investment Gold</PointHeadline>
          <CustomText>Wollen Sie sich von Goldbarren oder ihren Gold- oder Silbermünzen trennen?</CustomText>
          <PointImage src={goldImage} />
          <CustomText>
            <Strong>Goldbarren</Strong>
          </CustomText>
          <CustomText>
            Wir kaufen Ihre gebrauchten Goldbarren gerne zum aktuellen Tageskurs des Edelmetalls an, unabhängig von Prägeanstalt oder Bank, die diese
            gestempelt hat.
          </CustomText>
          <CustomText>Wie müssen Goldbarren beschaffen sein?</CustomText>
          <CustomText>
            Senden Sie uns Ihre Goldbarren einfach zu wie sie sind. Diese müssen nicht mehr originalverpackt oder eingeschweißt sein.
          </CustomText>
          <CustomText>Sie können kein Zertifikat finden? Auch in diesem Fall finden wir eine gute Lösung.</CustomText>
          <CustomText>
            Wir analysieren den Feingoldgehalt in unserem Labor. Nach der Ermittlung des Goldanteils und des Gewichts erhalten Sie unser faires
            Angebot und kaufen Ihre Goldbarren zum aktuellen Tageskurs des Edelmetalls an.
          </CustomText>
          <ImageSplit>
            <PointImage src={goldCointsImage} />
            <PointImage src={silverCoinsImage} />
          </ImageSplit>
          <CustomText>
            <Strong>Gold- oder Silbermünzen & Numismatik</Strong>
          </CustomText>
          <CustomText>
            Ob Goldmünzen wie Maple Leaf als 1 Unzen-Münze, Chinesischer Panda oder der Krügerrand oder alte Umlaufmünzen wie z.B. Deutsche Goldmark,
            Reichsgoldmünzen oder Silbermünzen wie den amerikanischer Silberdollar, Olympiamünzen – wir kaufen gerne jegliche Edelmetallmünzen an.{' '}
          </CustomText>
          <CustomText>
            Es spielt keine Rolle, aus welchem Land die Goldmünzen stammen, von welcher Präge- oder Scheideansstalt oder Bank. Lediglich der
            Goldanteil ist entscheidend. Wir ermitteln den exakten Goldanteil uns bestimmen auf dieser Basis der Wert. Sie entscheiden, ob Sie unser
            Angebot annehmen wollen.
          </CustomText>
          <CustomText>
            Gedenkmünzen und Sonderprägungen bewerten wir mit unserem numismatischen Sachverstand und machen Ihnen ein angemessenes Angebot.
          </CustomText>
          <PointHeadline>4. Zahngold und Edelmetallgegenstände</PointHeadline>
          <PointImage src={toothGoldImage} />
          <CustomText>
            <Strong>Zahngold</Strong>
          </CustomText>
          <CustomText>
            <Strong>„Zahngold“</Strong> besteht meist aus verschiedenen Edelmetallen in unterschiedlicher Zusammensetzung. Der Goldanteil bestimmt den
            Wert des Zahngoldes.
          </CustomText>
          <CustomText>
            <u>Achtung:</u> Es gibt „Zahngold“, dass{' '}
            <Strong>
              <u>kein</u>
            </Strong>{' '}
            Edelmetall enthält. Daher hat es leider keinen Ankaufswert. Dieses Zahngold hat einen silbergrauen Farbton. Fragen Sie Ihren Zahnarzt!
          </CustomText>
          <PointImage src={silverDishesImage} />
          <CustomText>
            <Strong>Silber/Versilbertes Besteck</Strong>
          </CustomText>
          <CustomText>
            Bayern Gold24 ist auf den Ankauf von <Strong>versilberten Bestecken, Messern, Hotelsilber</Strong> (z.B. Schalen, Becher, Tabletts,
            Kerzenhalter) spezialisiert. In Deutschland dominieren 90er oder 100er Versilberungen. Dies sagt aus, dass 90 g bzw. 100 g Feinsilber auf
            etwa 12 Essgabeln und 12 Löffeln abgeschieden wird. Zur Unterscheidung von echtsilbernen und versilberten Gegenständen, Besteckteilen oder
            Hotelsilber prüfen wir optisch auf vorhandene Punzen.
          </CustomText>
          <PointImage src={zinnImage} />
          <CustomText>
            <Strong>Zinn</Strong>
          </CustomText>
          <CustomText>
            Wir geben Zinn zurück in den Wertstoffkreislauf. Wir kaufen jegliche Arten von Zinn an: z.B. Geschirrzinn wie Zinnteller, Becher, Krüge
            und geschmolzene Zinnstangen oder -barren. Wir vergüten Ihnen den reinen Materialwert. Bitte haben Sie Verständnis, dass wir Sammlerwerte
            nicht berücksichtigen können.
          </CustomText>
          <CustomText>Achtung! Nicht alles, was glänzt ist Zinn! Zinn wird anhand der Punze von Zink und Bleigegenständen unterschieden.</CustomText>
        </Container>
      </StyledPurchase>
      <Hero>
        <CTAWrapper>
          <PurchaseCTA />
        </CTAWrapper>
      </Hero>
    </Layout>
  )
}

export default Purchase
