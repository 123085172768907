import styled from "styled-components";
import Image from "../../../UI/Image/Image";

export const StyledScrollToggle = styled.div`
  text-align: center;
  position: fixed;
  bottom: 0px;
  z-index: 997;
  padding-bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
`;

export const Toggle = styled(Image)`
  height: 30px;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  display: inline-block;
  ${({ invert }) =>
    invert &&
    `
      -webkit-transform:rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    `}
`;
