import Container from '../UI/Container/Container'
import { Header, StyledImprint, H2, P, Content } from './Imprint.Styled'
import Layout from '../UI/Layout/Layout'
import { useSelector } from 'react-redux'
import Hero from '../UI/Hero/Hero'
import heroImage from '../../assets/image/imprint/hero.jpg'

const Imprint = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <Layout>
      <Hero img={heroImage} />
      <StyledImprint>
        <Container>
          <Content>
            <Header>{translation.imprint.headline}</Header>
            <P>
              Bayern-Gold24
              <br />
              Monika Demirel
              <br />
              Luitpold-Leusser-Platz 1<br />
              D-86825 Bad Wörishofen
            </P>
            <P>
              Telefon: +49 (0) 8247 9985208
              <br />
              Mobil: 0151 62664214
              <br />
              eMail: info@bayern-gold24.de
            </P>
            <P>Verantwortlich für den Inhalt: Monika Demirel</P>
            <H2>Umsatzsteuer-ID:</H2>
            <P>
              Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz:
              <br />
              DE305758745
            </P>
            <H2>Bildrechte:</H2>
            <P>
              ©Monika Demirel
              <br />
              Fotolia: Zinnbecher©blende11.photo | Goldzähne©landstreicher | Diamond©ikonacolor | Schatzkiste©M.Schuppich | collection monnaies
              antiques©n3d-artphoto.com | Old cutlery©victoria p. | Schweizer Goldvreneli auf rustikalem Holztisch©Björn Wylezich | Pure
              Gold©ktsdesign
            </P>
            <H2>Alternative Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO und § 36 VSBG:</H2>
            <P>
              Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die Existenz der Europäischen Online-Streitbeilegungs-Plattform hinzuweisen,
              die für die Beilegung von Streitigkeiten genutzt werden kann, ohne dass ein Gericht eingeschaltet werden muss. Für die Einrichtung der
              Plattform ist die Europäische Kommission zuständig. Die Europäische Online-Streitbeilegungs-Plattform ist hier zu finden:
              http://ec.europa.eu/odr. Unsere E-Mail lautet: info@bayern-gold24.de
            </P>
            <P>
              Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am Streitbeilegungsverfahren im Rahmen der Europäischen
              Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer.
            </P>
            <H2>Disclaimer – rechtliche Hinweise:</H2>
            <P>
              § 1 Warnhinweis zu Inhalten
              <br />
              Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite
              übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der bereitgestellten kostenlosen und frei zugänglichen journalistischen
              Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des
              Anbieters wieder. Allein durch den Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei Vertragsverhältnis zwischen dem
              Nutzer und dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des Anbieters.
            </P>
            <P>
              § 2 Externe Links
              <br />
              Diese Website enthält Verknüpfungen zu Websites Dritter („externe Links“). Diese Websites unterliegen der Haftung der jeweiligen
              Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige
              Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle
              und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der
              Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist für den
              Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe
              Links unverzüglich gelöscht.
            </P>
            <P>
              § 3 Urheber- und Leistungsschutzrechte
              <br />
              Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber-
              und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen
              Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von
              Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche
              gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und
              strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist
              erlaubt.
            </P>
            <P>Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.</P>
            <P>
              § 4 Besondere Nutzungsbedingungen
              <br />
              Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen, wird an entsprechender
              Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen.
            </P>
          </Content>
        </Container>
      </StyledImprint>
    </Layout>
  )
}

export default Imprint
