import { useSelector } from 'react-redux'
import {
  StyledFooter,
  Column,
  Content,
  CustomLink,
  Copyright,
  CustomFlex,
  ImageFlex,
  LocationHeadline,
  LocationText,
  SocialIconLink,
} from './Footer.Styled'
import Flex from '../../Flex/Flex'
import Image from '../../Image/Image'
import logo from '../../../../assets/image/logo.png'
import facebookIcon from '../../../../assets/image/facebook.png'
import instagramIcon from '../../../../assets/image/instagram.png'

const Footer = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <StyledFooter>
      <Content>
        <Flex align={'flex-start'}>
          <Column>
            <ImageFlex wrap="no-wrap" gap="30px" justify={'left'}>
              <Image src={logo} height="75px" />
            </ImageFlex>
          </Column>
          <Column>
            <LocationHeadline text="Bad Wörishofen" />
            <LocationText>
              <br />
              Montag bis Freitag:
              <br />
              10 Uhr bis 18 Uhr
              <br />
              Samstag:
              <br />
              10 Uhr bis 13 Uhr
              <br />
              <br />
              Luitpold-Leusser-Platz 1
              <br />
              D-86825 Bad Wörishofen
              <br />
              <br />
              Telefon: <a href="tel: 08247 9985208">08247 9985208</a>
              <br />
              Mobil: <a href="tel:0151 62664214">0151 62664214</a>
              <br />
              E-Mail: <a href="mailto:info@bayern-gold24.de">info@bayern-gold24.de</a>
            </LocationText>
          </Column>
          <Column>
            <LocationHeadline text="Marktoberdorf im Uhrencafé" />
            <LocationText>
              <br />
              Dienstag:
              <br />
              10-13 Uhr & 14-18 Uhr
              <br />
              Donnerstag:
              <br />
              10-13 Uhr & 14-18 Uhr
              <br />
              Freitag:
              <br />
              9-13 Uhr
              <br />
              <br />
              <br />
              Salzstraße 23
              <br />
              D-87616 Marktoberdorf
              <br />
              <br />
              Telefon: <a href="tel: 08247 9985208">08247 9985208</a>
              <br />
              Mobil: <a href="tel:0151 62664214">0151 62664214</a>
              <br />
              E-Mail: <a href="mailto:info@bayern-gold24.de">info@bayern-gold24.de</a>
            </LocationText>
          </Column>
          <Column>
            <LocationHeadline text="Social Media" />
            <Flex wrap="no-wrap" justify={'left'} gap="30px">
              <SocialIconLink href="https://www.facebook.com/bayerngold24/" target={'_blank'} rel="noreferrer">
                <Image src={facebookIcon} height="50px" />
              </SocialIconLink>
              <SocialIconLink href="https://www.instagram.com/bayerngold24/" target={'_blank'} rel="noreferrer">
                <Image src={instagramIcon} height="50px" />
              </SocialIconLink>
            </Flex>
          </Column>
          <Column>
            <CustomFlex>
              <CustomLink href="/impressum" text={translation.imprint.headline} />
              <CustomLink href="/agb" text={translation.agb.headline} />
              <CustomLink href="/datenschutzbelehrung" text={translation.dataProtection.headline} />
            </CustomFlex>
          </Column>
        </Flex>
        <Copyright text={translation.footer.copyright} />
      </Content>
    </StyledFooter>
  )
}

export default Footer
