import { StyledService, ImageWrapper, CustomText, InitText, More, MoreText, MoreTextItem } from './Service.Styled'
import Image from '../../UI/Image/Image'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const Service = ({ img, data }) => {
  const translation = useSelector((s) => s.translation)
  const [show, setShow] = useState(false)
  return (
    <StyledService>
      <ImageWrapper>
        <Image src={img} maxHeight="100px" maxWidth="100px" />
      </ImageWrapper>
      <CustomText text={data.headline} align={'center'} font="bold" margin="10px 0" />
      <InitText dangerouslySetInnerHTML={{ __html: data.text }} />
      {show && (
        <MoreText>
          {Array.isArray(data.moreText) ? (
            data.moreText.map((text, index) => <MoreTextItem key={index} dangerouslySetInnerHTML={{ __html: text }} />)
          ) : (
            <MoreTextItem dangerouslySetInnerHTML={{ __html: data.moreText }} />
          )}
        </MoreText>
      )}
      <More onClick={() => setShow(!show)}>{show ? translation.servicesSection.lessLabel : translation.servicesSection.moreLabel}</More>
    </StyledService>
  )
}

export default Service
