import styled from 'styled-components'
import Text from '../../UI/Text/Text'

export const StyledService = styled.div`
  width: 50%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const ImageWrapper = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CustomText = styled(Text)``

export const InitText = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
`
export const More = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  font-family: 'bold';
  cursor: pointer;
`
export const MoreText = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
`

export const MoreTextItem = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  margin-top: 10px;
  strong,
  u,
  a {
    font-size: ${({ theme }) => theme.size.sm};
    font-family: 'bold';
  }
  a {
    color: ${({ theme }) => theme.color.primary};
  }
`
