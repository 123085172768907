import Container from '../UI/Container/Container'
import { Header, StyledDataProtection, H2, H3, P, UL, Content } from './DataProtection.Styled'
import Layout from '../UI/Layout/Layout'
import { useSelector } from 'react-redux'
import heroImage from '../../assets/image/data-protection/hero.jpg'
import Hero from '../UI/Hero/Hero'

const DataProtection = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <Layout>
      <Hero img={heroImage} />
      <StyledDataProtection>
        <Container>
          <Content>
            <Header>{translation.dataProtection.headline}</Header>
            <P>Die nachfolgende Datenschutzerklärung gilt für die Nutzung unseres Online-Angebots Bayern-Gold24 nachfolgend www.bayern-gold24.de</P>
            <P>
              Wir messen dem Datenschutz große Bedeutung bei. Die Erhebung und Verarbeitung Ihrer personenbezogenen Daten geschieht unter Beachtung
              der geltenden datenschutzrechtlichen Vorschriften, insbesondere der Datenschutzgrundverordnung (DSGVO).
            </P>
            <H2>1. Verantwortlicher</H2>
            <P>Verantwortlicher für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten im Sinne von Art. 4 Nr. 7 DSGVO ist</P>
            <P>Demirel Monika, Luitpold Leusser Platz 1 86825 Bad Wörishofen.</P>
            <P>
              Sofern Sie der Erhebung, Verarbeitung oder Nutzung Ihrer Daten durch uns nach Maßgabe dieser Datenschutzbestimmungen insgesamt oder für
              einzelne Maßnahmen widersprechen wollen, können Sie Ihren Widerspruch an den Verantwortlichen richten.
            </P>
            <P>Sie können diese Datenschutzerklärung jederzeit speichern und ausdrucken.</P>
            <H2>2. Allgemeine Zwecke der Verarbeitung</H2>
            <P>Wir verwenden personenbezogene Daten zum Zweck des Betriebs der Website www.bayern-gold24.de</P>
            <H2>3 Welche Daten wir verwenden und warum</H2>
            <H3>3.1 Hosting</H3>
            <P>
              Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
              Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische
              Wartungsleistungen, die wir zum Zweck des Betriebs der Website einsetzen.
            </P>
            <P>
              Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und
              Kommunikationsdaten von Kunden, Interessenten und Besuchern dieser Website auf Grundlage unserer berechtigten Interessen an einer
              effizienten und sicheren Zurverfügungstellung unserer Website gem. Art. 6 Abs. 1 S. 1 f) DSGVO i.V.m. Art. 28 DSGVO.
            </P>
            <H3>3.2 Zugriffsdaten</H3>
            <P>
              Wir sammeln Informationen über Sie, wenn Sie diese Website nutzen. Wir erfassen automatisch Informationen über Ihr Nutzungsverhalten und
              Ihre Interaktion mit uns und registrieren Daten zu Ihrem Computer oder Mobilgerät. Wir erheben, speichern und nutzen Daten über jeden
              Zugriff auf unsere Website (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören:
            </P>
            <P>
              <UL>
                <li>Name und URL der abgerufenen Datei</li>
                <li>Datum und Uhrzeit des Abrufs</li>
                <li>übertragene Datenmenge</li>
                <li>Meldung über erfolgreichen Abruf (HTTP response code)</li>
                <li>Browsertyp und Browserversion</li>
                <li>Betriebssystem</li>
                <li>Referer URL (d.h. die zuvor besuchte Seite)</li>
                <li>Websites, die vom System des Nutzers über unsere Website aufgerufen werden</li>
                <li>Internet-Service-Provider des Nutzers</li>
                <li>IP-Adresse und der anfragende Provider</li>
              </UL>
            </P>
            <P>
              Wir nutzen diese Protokolldaten ohne Zuordnung zu Ihrer Person oder sonstiger Profilerstellung für statistische Auswertungen zum Zweck
              des Betriebs, der Sicherheit und der Optimierung unserer Website, aber auch zur anonymen Erfassung der Anzahl der Besucher auf unserer
              Website (traffic) sowie zum Umfang und zur Art der Nutzung unserer Website und Dienste, ebenso zu Abrechnungszwecken, um die Anzahl der
              von Kooperationspartnern erhaltenen Clicks zu messen. Aufgrund dieser Informationen können wir personalisierte und standortbezogene
              Inhalte zur Verfügung stellen und den Datenverkehr analysieren, Fehler suchen und beheben und unsere Dienste verbessern.
            </P>
            <P>Hierin liegt auch unser berechtigtes Interesse gemäß Art 6 Abs. 1 S. 1 f) DSGVO.</P>
            <P>
              Wir behalten uns vor, die Protokolldaten nachträglich zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der berechtigte Verdacht
              einer rechtswidrigen Nutzung besteht. IP-Adressen speichern wir für einen begrenzten Zeitraum in den Logfiles, wenn dies für
              Sicherheitszwecke erforderlich oder für die Leistungserbringung oder die Abrechnung einer Leistung nötig ist, z. B. wenn Sie eines
              unserer Angebote nutzen. Nach Abbruch des Vorgangs der Bestellung oder nach Zahlungseingang löschen wir die IP-Adresse, wenn diese für
              Sicherheitszwecke nicht mehr erforderlich ist. IP-Adressen speichern wir auch dann, wenn wir den konkreten Verdacht einer Straftat im
              Zusammenhang mit der Nutzung unserer Website haben. Außerdem speichern wir als Teil Ihres Accounts das Datum Ihres letzten Besuchs (z.B.
              bei Registrierung, Login, Klicken von Links etc.).
            </P>
            <H3>3.3 Cookies</H3>
            <P>
              Wir verwenden sogenannte Session-Cookies, um unsere Website zu optimieren. Ein Session-Cookie ist eine kleine Textdatei, die von den
              jeweiligen Servern beim Besuch einer Internetseite verschickt und auf Ihrer Festplatte zwischengespeichert wird. Diese Datei als solche
              enthält eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen.
              Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Diese Cookies werden gelöscht, nachdem Sie
              Ihren Browser schließen. Sie dienen z. B. dazu, dass Sie die Warenkorbfunktion über mehrere Seiten hinweg nutzen können.
            </P>
            <P>
              Wir verwenden in geringem Umfang auch persistente Cookies (ebenfalls kleine Textdateien, die auf Ihrem Endgerät abgelegt werden), die
              auf Ihrem Endgerät verbleiben und es uns ermöglichen, Ihren Browser beim nächsten Besuch wiederzuerkennen. Diese Cookies werden auf
              Ihrer Festplatte gespeichert und löschen sich nach der vorgegebenen Zeit von allein. Ihre Lebensdauer beträgt 1 Monat bis 10 Jahre. So
              können wir Ihnen unser Angebot nutzerfreundlicher, effektiver und sicherer präsentieren und Ihnen beispielsweise speziell auf Ihre
              Interessen abgestimmte Informationen auf der Seite anzeigen.
            </P>
            <P>
              Unser berechtigtes Interesse an der Nutzung der Cookies gemäß Art 6 Abs. 1 S. 1 f) DSGVO liegt darin, unsere Website nutzerfreundlicher,
              effektiver und sicherer zu machen.
            </P>
            <P>In den Cookies werden etwa folgende Daten und Informationen gespeichert:</P>
            <P>
              <UL>
                <li> Log-In-Informationen</li>
                <li>Spracheinstellungen</li>
                <li>eingegebene Suchbegriffe</li>
                <li>Informationen über die Anzahl der Aufrufe unserer Website sowie Nutzung einzelner Funktionen unseres Internetauftritts.</li>
              </UL>
            </P>
            <P>
              Bei Aktivierung des Cookies wird diesem eine Identifikationsnummer zugewiesen und eine Zuordnung Ihrer personenbezogenen Daten zu dieser
              Identifikationsnummer wird nicht vorgenommen. Ihr Name, Ihre IP-Adresse oder ähnliche Daten, die eine Zuordnung des Cookies zu Ihnen
              ermöglichen würden, werden nicht in den Cookie eingelegt. Auf Basis der Cookie-Technologie erhalten wir lediglich pseudonymisierte
              Informationen, beispielsweise darüber, welche Seiten unseres Shops besucht wurden, welche Produkte angesehen wurden, etc.
            </P>
            <P>
              Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies vorab informiert werden und im Einzelfall entscheiden
              können, ob Sie die Annahme von Cookies für bestimmte Fälle oder generell ausschließen, oder dass Cookies komplett verhindert werden.
              Dadurch kann die Funktionalität der Website eingeschränkt werden.
            </P>
            <H3>3.4 Daten zur Erfüllung unserer vertraglichen Pflichten</H3>
            <P>
              Wir verarbeiten personenbezogene Daten, die wir zur Erfüllung unserer vertraglichen Pflichten benötigen, etwa Name, Adresse,
              E-Mail-Adresse, bestellte Produkte, Rechnungs- und Zahlungsdaten. Die Erhebung dieser Daten ist für den Vertragsschluss erforderlich.
            </P>
            <P>
              Die Löschung der Daten erfolgt nach Ablauf der Gewährleistungsfristen und gesetzlicher Aufbewahrungsfristen. Daten, die mit einem
              Nutzerkonto verknüpft sind (siehe unten), bleiben in jedem Fall für die Zeit der Führung dieses Kontos erhalten.
            </P>
            <P>
              Die Rechtgrundlage für die Verarbeitung dieser Daten ist Art. 6 Abs. 1 S. 1 b) DSGVO, denn diese Daten werden benötigt, damit wir unsere
              vertraglichen Pflichten Ihnen gegenüber erfüllen können.
            </P>
            <H3>3.5 Nutzerkonto</H3>
            <P>
              Sie können auf unserer Website ein Nutzerkonto anlegen. Wünschen Sie dies, so benötigen wir die beim Login abgefragten personenbezogenen
              Daten. Beim späteren Einloggen werden nur Ihre Email bzw. Benutzername und das von Ihnen gewählte Passwort benötigt.
            </P>

            <P>
              Für die Neuregistrierung erheben wir Stammdaten (z. B. Name, Adresse), Kommunikationsdaten (z. B. E-Mail-Adresse) und Zahlungsdaten
              (Bankverbindung) sowie Zugangsdaten (Benutzername u. Passwort).
            </P>

            <P>
              Um Ihre ordnungsgemäße Anmeldung sicherzustellen und unautorisierte Anmeldungen durch Dritte zu verhindern, erhalten Sie nach Ihrer
              Registrierung einen Aktivierungslink per E-Mail, um Ihren Account zu aktivieren. Erst nach erfolgter Registrierung speichern wir die von
              Ihnen übermittelten Daten dauerhaft in unserem System.
            </P>

            <P>
              Sie können ein einmal angelegtes Nutzerkonto jederzeit von uns löschen lassen, ohne dass hierfür andere als die Übermittlungskosten nach
              den Basistarifen entstehen. Eine Mitteilung in Textform an die unter Ziffer 1 genannten Kontaktdaten (z.B. E-Mail, Fax, Brief) reicht
              hierfür aus. Wir werden dann Ihre gespeicherten personenbezogenen Daten löschen, soweit wir diese nicht noch zur Abwicklung von
              Bestellungen oder aufgrund gesetzlicher Aufbewahrungspflichten speichern müssen.
            </P>

            <P>Rechtgrundlage für die Verarbeitung dieser Daten ist Ihre Einwilligung gemäß Art. 6 Abs. 1 S. 1 a) DSGVO.</P>
            <H3>3.6 Newsletter</H3>
            <P>
              Zur Anmeldung für den Newsletter werden die im Anmeldeprozess abgefragten Daten benötigt. Die Anmeldung zum Newsletter wird
              protokolliert. Nach der Anmeldung erhalten sie auf die angegebene Emailadresse eine Nachricht, in der Sie um die Bestätigung der
              Anmeldung gebeten werden („Double Opt-in“). Das ist notwendig, damit sich nicht Dritte mit ihrer Emailadresse anmelden können.
            </P>

            <P>Sie können jederzeit Ihre Einwilligung zum Empfang des Newsletters widerrufen und somit den Newsletter abbestellen.</P>

            <P>
              Wir speichern die Anmeldedaten solange diese für den Versand des Newsletters benötigt werden. Die Protokollierung der Anmeldung und die
              Versandadresse speichern wir, solange ein Interesse am Nachweis der ursprünglich gegebenen Einwilligung bestand, in der Regel sind das
              die Verjährungsfristen für zivilrechtliche Ansprüche, mithin maximal drei Jahre.
            </P>

            <P>
              Rechtsgrundlage für den Versand des Newsletters ist Ihre Einwilligung gem. Art. 6 Abs. 1 S. 1 a) iVm Art. 7 DSGVO iVm § 7 Abs. 2 Nr. 3
              UWG. Rechtsgrundlage für die Protokollierung der Anmeldung ist unser berechtigtes Interesse am Nachweis, dass der Versand mit Ihrer
              Einwilligung vorgenommen wurde.
            </P>

            <P>
              Sie können die Anmeldung jederzeit rückgängig machen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen
              entstehen. Eine Mitteilung in Textform an die unter Ziffer 1 genannten Kontaktdaten (z.B. E-Mail, Fax, Brief) reicht hierfür aus.
              Selbstverständlich finden Sie auch in jedem Newsletter einen Abmelde-Link.
            </P>
            <H3>3.7 Produktempfehlungen</H3>
            <P>
              Wir senden Ihnen unabhängig vom Newsletter regelmäßig Produktempfehlungen per E-Mail zu. Auf diese Weise lassen wir Ihnen Informationen
              über Produkte aus unserem Angebot zukommen, für die Sie sich auf Grundlage Ihrer letzten Käufe von Waren oder Dienstleistungen bei uns
              interessieren könnten. Dabei richten wir uns streng nach den gesetzlichen Vorgaben. Dem können Sie jederzeit widersprechen, ohne dass
              hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen. Eine Mitteilung in Textform an die unter Ziffer 1 genannten
              Kontaktdaten (z.B. E-Mail, Fax, Brief) reicht hierfür aus. Selbstverständlich finden Sie auch in jeder E-Mail einen Abmelde-Link.
            </P>
            <P>Rechtsgrundlage hierfür ist die gesetzliche Erlaubnis nach Art. 6 Abs. 1 S. 1 f) DSGVO iVm § 7 Abs. 3 UWG.</P>
            <H3>3.8 E-Mail Kontakt</H3>
            <P>
              Wenn Sie mit uns in Kontakt treten (z. B. per Kontaktformular oder E-Mail), verarbeiten wir Ihre Angaben zur Bearbeitung der Anfrage
              sowie für den Fall, dass Anschlussfragen entstehen.
            </P>
            <P>
              Erfolgt die Datenverarbeitung zur Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin erfolgen, bzw., wenn Sie bereits
              unser Kunde sind, zur Durchführung des Vertrages, ist Rechtsgrundlage für diese Datenverarbeitung Art. 6 Abs. 1 S. 1 b) DSGVO.
            </P>
            <P>
              Weitere personenbezogene Daten verarbeiten wir nur, wenn Sie dazu einwilligen (Art. 6 Abs. 1 S. 1 a) DSGVO) oder wir ein berechtigtes
              Interesse an der Verarbeitung Ihrer Daten haben (Art. 6 Abs. 1 S. 1 f) DSGVO). Ein berechtigtes Interesse liegt z. B. darin, auf Ihre
              E-Mail zu antworten.
            </P>
            <H2>4. Google Analytics</H2>
            <P>
              Wir benutzen Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“,
              Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch
              den Cookie erzeugten Informationen über Benutzung dieser Website durch die Seitenbesucher werden in der Regel an einen Server von Google
              in den USA übertragen und dort gespeichert.
            </P>

            <P>Hierin liegt auch unser berechtigtes Interesse gemäß Art 6 Abs. 1 S. 1 f) DSGVO.</P>

            <P>
              Google hat sich dem zwischen der Europäischen Union und den USA geschlossenen Privacy-Shield-Abkommen unterworfen und sich zertifiziert.
              Dadurch verpflichtet sich Google, die Standards und Vorschriften des europäischen Datenschutzrechts einzuhalten. Nähere Informationen
              können Sie dem nachfolgend verlinkten Eintrag entnehmen: https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active.
            </P>

            <P>
              Wir haben die IP-Anonymisierung auf dieser Website aktiviert (anonymizeIp). Dadurch wird Ihre IP-Adresse von Google jedoch innerhalb von
              Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor
              gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. In unserem
              Auftrag wird Google diese Informationen benutzen, um die Nutzung der Website durch Sie auszuwerten, um Reports über die
              Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen uns
              gegenüber zu erbringen.
            </P>

            <P>
              Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
              Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
              darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
            </P>

            <P>
              Sie können darüber hinaus die Übertragung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
              IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare
              Browser-Plugin herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de.
            </P>

            <P>
              Alternativ zum Browser-Plugin oder innerhalb von Browsern auf mobilen Geräten können Sie auf den folgenden Link klicken, um ein
              Opt-Out-Cookie zu setzen, der die Erfassung durch Google Analytics innerhalb dieser Website zukünftig verhindert (dieses Opt-Out-Cookie
              funktioniert nur in diesem Browser und nur für diese Domain. Löschen Sie die Cookies in Ihrem Browser, müssen Sie diesen Link erneut
              klicken): [Google Analytics deaktivieren]
            </P>
            <H3>4.1 Social Media Plug-ins</H3>
            <P>
              Wir setzen auf unserer Website auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO Social Plug-ins der sozialen Netzwerke Facebook,
              Twitter und Instagram ein, um unsere Kanzlei hierüber bekannter zu machen. Der dahinterstehende werbliche Zweck ist als berechtigtes
              Interesse im Sinne der DSGVO anzusehen. Die Verantwortung für den datenschutzkonformen Betrieb ist durch deren jeweiligen Anbieter zu
              gewährleisten. Die Einbindung dieser Plug-ins durch uns erfolgt im Wege der sogenannten Zwei-Klick-Methode um Besucher unserer Webseite
              bestmöglich zu schützen.
            </P>
            <P>a) Facebook</P>
            <P>
              Auf unserer Website kommen Social-Media Plugins von Facebook zum Einsatz, um deren Nutzung persönlicher zu gestalten. Hierfür nutzen wir
              den „LIKE“ oder „TEILEN“-Button. Es handelt sich dabei um ein Angebot von Facebook.
            </P>
            <P>
              Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, baut Ihr Browser eine direkte Verbindung mit den
              Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook direkt an Ihren Browser übermittelt und von diesem in die Webseite
              eingebunden.
            </P>
            <P>
              Durch die Einbindung der Plugins erhält Facebook die Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts
              aufgerufen hat, auch wenn Sie kein Facebook-Konto besitzen oder gerade nicht bei Facebook eingeloggt sind. Diese Information
              (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server von Facebook in den USA übermittelt und dort
              gespeichert.
            </P>
            <P>
              Sind Sie bei Facebook eingeloggt, kann Facebook den Besuch unserer Website Ihrem Facebook-Konto direkt zuordnen. Wenn Sie mit den
              Plugins interagieren, zum Beispiel den „LIKE“ oder „TEILEN“-Button betätigen, wird die entsprechende Information ebenfalls direkt an
              einen Server von Facebook übermittelt und dort gespeichert. Die Informationen werden zudem auf Facebook veröffentlicht und Ihren
              Facebook-Freunden angezeigt.
            </P>
            <P>
              Facebook kann diese Informationen zum Zwecke der Werbung, Marktforschung und bedarfsgerechten Gestaltung der Facebook-Seiten benutzen.
              Hierzu werden von Facebook Nutzungs-, Interessen- und Beziehungsprofile erstellt, z.B. um Ihre Nutzung unserer Website im Hinblick auf
              die Ihnen bei Facebook eingeblendeten Werbeanzeigen auszuwerten, andere Facebook-Nutzer über Ihre Aktivitäten auf unserer Website zu
              informieren und um weitere mit der Nutzung von Facebook verbundene Dienstleistungen zu erbringen.
            </P>
            <P>
              Wenn Sie nicht möchten, dass Facebook die über unseren Webauftritt gesammelten Daten Ihrem Facebook-Konto zuordnet, müssen Sie sich vor
              Ihrem Besuch unserer Website bei Facebook ausloggen.
            </P>
            <P>
              Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre diesbezüglichen Rechte
              und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen
              (https://www.facebook.com/about/privacy/) von Facebook.
            </P>
            <P>b) Twitter</P>
            <P>
              Auf unseren Internetseiten sind Plugins des Kurznachrichtennetzwerks der Twitter Inc. (Twitter) integriert. Die Twitter-Plugins
              (tweet-Button) erkennen Sie an dem Twitter-Logo auf unserer Seite. Eine Übersicht über tweet-Buttons finden Sie hier
              (https://about.twitter.com/resources/buttons).
            </P>

            <P>
              Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, wird eine direkte Verbindung zwischen Ihrem Browser
              und dem Twitter-Server hergestellt. Twitter erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben.
              Wenn Sie den Twitter „tweet-Button“ anklicken, während Sie in Ihrem Twitter-Account eingeloggt sind, können Sie die Inhalte unserer
              Seiten auf Ihrem Twitter-Profil verlinken. Dadurch kann Twitter den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen
              darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Twitter
              erhalten.
            </P>

            <P>
              Wenn Sie nicht wünschen, dass Twitter den Besuch unserer Seiten zuordnen kann, loggen Sie sich bitte aus Ihrem Twitter-Benutzerkonto
              aus.
            </P>

            <P>Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Twitter(https://twitter.com/privacy).</P>
            <P>c) Instagram</P>
            <P>
              Auf unserer Website werden auch sogenannte Social Plugins („Plugins“) von Instagram verwendet, das von der Instagram LLC., 1601 Willow
              Road, Menlo Park, CA 94025, USA („Instagram“) betrieben wird.
            </P>
            <P>Die Plugins sind mit einem Instagram-Logo beispielsweise in Form einer „Instagram-Kamera“ gekennzeichnet.</P>
            <P>
              Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, stellt Ihr Browser eine direkte Verbindung zu den
              Servern von Instagram her. Der Inhalt des Plugins wird von Instagram direkt an Ihren Browser übermittelt und in die Seite eingebunden.
              Durch diese Einbindung erhält Instagram die Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat,
              auch wenn Sie kein Instagram-Profil besitzen oder gerade nicht bei Instagram eingeloggt sind.
            </P>
            <P>
              Diese Information (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server von Instagram in die USA übermittelt
              und dort gespeichert. Sind Sie bei Instagram eingeloggt, kann Instagram den Besuch unserer Website Ihrem Instagram-Account unmittelbar
              zuordnen. Wenn Sie mit den Plugins interagieren, zum Beispiel das „Instagram“-Button betätigen, wird diese Information ebenfalls direkt
              an einen Server von Instagram übermittelt und dort gespeichert.
            </P>
            <P>Die Informationen werden außerdem auf Ihrem Instagram-Account veröffentlicht und dort Ihren Kontakten angezeigt.</P>
            <P>
              Wenn Sie nicht möchten, dass Instagram die über unseren Webauftritt gesammelten Daten unmittelbar Ihrem Instagram-Account zuordnet,
              müssen Sie sich vor Ihrem Besuch unserer Website bei Instagram ausloggen.
            </P>
            <P>Weitere Informationen hierzu Sie in der Datenschutzerklärung(https://help.instagram.com/155833707900388) von Instagram.</P>
            <H3>Betroffenenrechte</H3>
            <P>Sie haben das Recht:</P>
            <P>
              <UL>
                <li>
                  gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft
                  über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten
                  offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der
                  Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben
                  wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen
                  Informationen zu deren Einzelheiten verlangen;
                </li>
                <li>
                  gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen
                  Daten zu verlangen;
                </li>
                <li>
                  gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur
                  Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des
                  öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
                </li>
                <li>
                  gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten
                  von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr
                  benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21
                  DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
                </li>
                <li>
                  gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und
                  maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
                </li>
                <li>
                  gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die
                  Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und
                </li>
                <li>
                  gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde
                  Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Kanzleisitzes wenden.
                </li>
                <li>Widerspruchsrecht</li>
              </UL>
            </P>
            <P>
              Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden,
              haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür
              Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren
              Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird. Möchten Sie von
              Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an: info@bayern-gold24.de
            </P>
            <H3>Datensicherheit</H3>
            <P>
              Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten
              Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls
              Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite
              unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise
              Schloss-Symbols in der unteren Statusleiste Ihres Browsers.
            </P>
            <P>
              Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder
              vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen.
              Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
            </P>
            <H3>Aktualität und Änderung dieser Datenschutzerklärung</H3>
            <P>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai 2018.</P>
            <P>
              Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher
              Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf
              der Website unter https://www.bayern-gold24.de von Ihnen abgerufen und ausgedruckt werden.
            </P>
            <H2>9 Speicherdauer</H2>
            <P>
              Sofern nicht spezifisch angegeben speichern wir personenbezogene Daten nur so lange, wie dies zur Erfüllung der verfolgten Zwecke
              notwendig ist.
            </P>

            <P>
              In einigen Fällen sieht der Gesetzgeber die Aufbewahrung von personenbezogenen Daten vor, etwa im Steuer- oder Handelsrecht. In diesen
              Fällen werden die Daten von uns lediglich für diese gesetzlichen Zwecke weiter gespeichert, aber nicht anderweitig verarbeitet und nach
              Ablauf der gesetzlichen Aufbewahrungsfrist gelöscht.
            </P>
            <H2>10 Ihre Rechte als von der Datenverarbeitung Betroffener</H2>
            <P>
              Nach den anwendbaren Gesetzen haben Sie verschiedene Rechte bezüglich Ihrer personenbezogenen Daten. Möchten Sie diese Rechte geltend
              machen, so richten Sie Ihre Anfrage bitte per E-Mail oder per Post unter eindeutiger Identifizierung Ihrer Person an die in Ziffer 1
              genannte Adresse.
            </P>

            <P>Nachfolgend finden Sie eine Übersicht über Ihre Rechte.</P>
            <H3>10.1 Recht auf Bestätigung und Auskunft</H3>

            <P>Sie haben das Recht auf eine übersichtliche Auskunft über die Verarbeitung Ihrer personenbezogenen Daten.</P>

            <P>Im Einzelnen:</P>

            <P>
              Sie haben jederzeit das Recht, von uns eine Bestätigung darüber zu erhalten, ob Sie betreffende personenbezogene Daten verarbeitet
              werden. Ist dies der Fall, so haben Sie das Recht, von uns eine unentgeltliche Auskunft über die zu Ihnen gespeicherten
              personenbezogenen Daten nebst einer Kopie dieser Daten zu verlangen. Des Weiteren besteht ein Recht auf folgende Informationen:
            </P>
            <P>
              <UL>
                <li>die Verarbeitungszwecke;</li>
                <li>die Kategorien personenbezogener Daten, die verarbeitet werden;</li>
                <li>
                  die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch
                  offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen;
                </li>
                <li>
                  falls möglich, die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die
                  Kriterien für die Festlegung dieser Dauer;
                </li>
                <li>
                  das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten oder auf Einschränkung der
                  Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
                </li>
                <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
                <li>wenn die personenbezogenen Daten nicht bei Ihnen erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten;</li>
                <li>
                  das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß 22Abs. 1 und 4 DSGVO und – zumindest in
                  diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer
                  derartigen Verarbeitung für Sie.
                </li>
              </UL>
            </P>
            <P>
              Werden personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt, so haben Sie das Recht, über die
              geeigneten Garantien gemäß Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
            </P>
            <H3>10.2 Recht auf Berichtigung</H3>
            <P>
              Sie haben das Recht, von uns die Berichtigung und ggf. auch Vervollständigung Sie betreffender personenbezogener Daten zu verlangen.
            </P>

            <P>Im Einzelnen:</P>

            <P>
              Sie haben das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Unter
              Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch
              mittels einer ergänzenden Erklärung – zu verlangen.
            </P>
            <H3>10.3 Recht auf Löschung („Recht auf Vergessenwerden“)</H3>
            <P>In einer Reihe von Fällen sind wir verpflichtet, Sie betreffende personenbezogene Daten zu löschen.</P>

            <P>Im Einzelnen:</P>

            <P>
              Sie haben gemäß Art. 17 Abs. 1 DSGVO das Recht, von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht
              werden, und wir sind verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
            </P>

            <P>
              <UL>
                <li>
                  Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr
                  notwendig.
                </li>
                <li>
                  Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6Abs. 1 1 a) DSGVO oder Art. 9 Abs. 2 a) DSGVO stützte,
                  und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
                </li>
                <li>
                  Sie legen gemäß Art. 21Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für
                  die Verarbeitung vor, oder Sie legen gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
                </li>
                <li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
                <li>
                  Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
                  Mitgliedstaaten erforderlich, dem wir unterliegen.
                </li>
                <li>
                  Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8Abs. 1 DSGVO erhoben.
                </li>
              </UL>
            </P>

            <P>
              Haben wir die personenbezogenen Daten öffentlich gemacht und sind wir gemäß Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so
              treffen wir unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer
              Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie von
              ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten
              verlangt haben.
            </P>
            <H3>10.4 Recht auf Einschränkung der Verarbeitung</H3>
            <P>
              In einer Reihe von Fällen sind Sie berechtigt, von uns eine Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </P>

            <P>Im Einzelnen:</P>

            <P>Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:</P>
            <P>
              <UL>
                <li>
                  die Richtigkeit der personenbezogenen Daten wird von Ihnen bestritten, und zwar für eine Dauer, die es uns ermöglicht, die
                  Richtigkeit der personenbezogenen Daten zu überprüfen,
                </li>
                <li>
                  die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnten und stattdessen die Einschränkung der
                  Nutzung der personenbezogenen Daten verlangt haben;
                </li>
                <li>
                  wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie die Daten jedoch zur Geltendmachung,
                  Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
                </li>
                <li>
                  Sie Widerspruch gegen die Verarbeitung gemäß Art. 21Abs. 1 DSGVO eingelegt haben, solange noch nicht feststeht, ob die berechtigten
                  Gründe unseres Unternehmens gegenüber den Ihren überwiegen.
                </li>
              </UL>
            </P>
            <H3>10.5 Recht auf Datenübertragbarkeit</H3>
            <P>
              Sie haben das Recht, Sie betreffende personenbezogene Daten maschinenlesbar zu erhalten, zu übermitteln, oder von uns übermitteln zu
              lasen.
            </P>

            <P>Im Einzelnen:</P>

            <P>
              Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen
              und maschinenlesbaren Format zu erhalten, und Sie haben das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns
              zu übermitteln, sofern
            </P>
            <P>
              <UL>
                <li>
                  die Verarbeitung auf einer Einwilligung gemäß Art. 6Abs. 1 1 a) DSGVO oder Art. 9 Abs. 2 a) DSGVO oder auf einem Vertrag gemäß Art.
                  6 Abs. 1 S. 1 b) DSGVO beruht und
                </li>
                <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
              </UL>
            </P>
            <P>
              Bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Absatz 1 haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten
              direkt von uns einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist.
            </P>
            <H3>10.6 Widerspruchsrecht</H3>
            <P>
              Sie haben das Recht, aus einer rechtmäßigen Verarbeitung Ihrer personenbezogenen Daten durch uns zu widersprechen, wenn sich dies aus
              Ihrer besonderen Situation begründet und unsere Interessen an der Verarbeitung nicht überwiegen.
            </P>

            <P>Im Einzelnen:</P>

            <P>
              Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
              personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 S. 1 e) oder f) DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein
              auf diese Bestimmungen gestütztes Profiling. Wir verarbeiten die personenbezogenen Daten nicht mehr, es sei denn, wir können zwingende
              schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient
              der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
            </P>

            <P>
              Werden personenbezogene Daten von uns verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen
              die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling,
              soweit es mit solcher Direktwerbung in Verbindung steht.
            </P>

            <P>
              Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Sie betreffende Verarbeitung Sie
              betreffender personenbezogener Daten, die zu wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß
              Art. 89 Abs. 1 DSGVO erfolgt, Widerspruch einzulegen, es sei denn, die Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse
              liegenden Aufgabe erforderlich.
            </P>
            <H3>10.7 Automatisierte Entscheidungen einschließlich Profiling</H3>
            <P>Eine automatisierte Entscheidungsfindung auf der Grundlage der erhobenen personenbezogenen Daten findet nicht statt.</P>
            <P>
              Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden
              Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
              beeinträchtigt.
            </P>
            <H3>10.8 Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</H3>
            <P>Sie haben das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.</P>
            <H3>10.9 Recht auf Beschwerde bei einer Aufsichtsbehörde</H3>
            <P>
              Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres
              Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
              personenbezogenen Daten rechtswidrig ist.
            </P>
            <H2>11 Datensicherheit</H2>
            <P>Wir sind um die Sicherheit Ihrer Daten im Rahmen der geltenden Datenschutzgesetze und technischen Möglichkeiten maximal bemüht.</P>

            <P>
              Ihre persönlichen Daten werden bei uns verschlüsselt übertragen. Dies gilt für Ihre Bestellungen und auch für das Kundenlogin. Wir
              nutzen das Codierungssystem SSL (Secure Socket Layer), weisen jedoch darauf hin, dass die Datenübertragung im Internet (z.B. bei der
              Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
              möglich.
            </P>

            <P>
              Zur Sicherung Ihrer Daten unterhalten wir technische und organisatorische Sicherungsmaßnahmen entsprechend Art. 32 DSGVO, die wir immer
              wieder dem Stand der Technik anpassen.
            </P>

            <P>
              Wir gewährleisten außerdem nicht, dass unser Angebot zu bestimmten Zeiten zur Verfügung steht; Störungen, Unterbrechungen oder Ausfälle
              können nicht ausgeschlossen werden. Die von uns verwendeten Server werden regelmäßig sorgfältig gesichert.
            </P>
            <H2>12 Weitergabe von Daten an Dritte, keine Datenübertragung ins Nicht-EU-Ausland</H2>
            <P>Grundsätzlich verwenden wir Ihre personenbezogenen Daten nur innerhalb unseres Unternehmens.</P>

            <P>
              Wenn und soweit wir Dritte im Rahmen der Erfüllung von Verträgen einschalten (etwa Logistik-Dienstleister), erhalten diese
              personenbezogene Daten nur in dem Umfang, in welchem die Übermittlung für die entsprechende Leistung erforderlich ist.
            </P>

            <P>
              Für den Fall, dass wir bestimmte Teile der Datenverarbeitung auslagern („Auftragsverarbeitung“), verpflichten wir Auftragsverarbeiter
              vertraglich dazu, personenbezogene Daten nur im Einklang mit den Anforderungen der Datenschutzgesetze zu verwenden und den Schutz der
              Rechte der betroffenen Person zu gewährleisten.
            </P>

            <P>
              Eine Datenübertragung an Stellen oder Personen außerhalb der EU außerhalb des in dieser Erklärung in Ziffer 4 genannten Falls findet
              nicht statt und ist nicht geplant.
            </P>
            <br />
            <br />
            <br />
            <P>Ansprechpartner für Datenschutz</P>
            <P>
              Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Sperrung oder Löschung
              von Daten sowie Widerruf erteilter Einwilligungen wenden Sie sich bitte an:
            </P>

            <P>
              Demirel Monika
              <br />
              Luitpold-Leusser-Platz 1<br />
              86825 Bad Wörishofen
            </P>

            <P>
              Telefon: 08247 9985208
              <br />
              Fax: 08247 9985207
              <br />
              E-Mail: info@bayern-gold24.de
            </P>

            <P>Stand: Mai 2018</P>
          </Content>
        </Container>
      </StyledDataProtection>
    </Layout>
  )
}

export default DataProtection
