import styled from 'styled-components'
import Text from '../UI/Text/Text'

export const StyledWeddingRingConfigurator = styled.div`
  padding: 50px 0px;
`

export const Headline = styled(Text)`
  font-size: ${({ theme }) => theme.size.lg};
  font-family: 'bold';
  margin-top: 50px;
`

export const CustomText = styled.div`
  margin: 15px 0px;
  a {
    color: ${({ theme }) => theme.color.font.gold};
    text-decoration: none;
    &:hover {
      font-weight: bold;
    }
  }
`
