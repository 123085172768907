import { createGlobalStyle } from 'styled-components'

import StolzlBold from './assets/font/Stolzl/Stolzl-Bold.ttf'
import StolzlBook from './assets/font/Stolzl/Stolzl-Book.ttf'
import StolzlThin from './assets/font/Stolzl/Stolzl-Thin.ttf'
import StolzlRegular from './assets/font/Stolzl/Stolzl-Regular.ttf'

const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: "headline";
  font-weight: thin;
  src: url(${StolzlRegular}) format("truetype");
}

@font-face {
  font-family: "bold";
  src: url(${StolzlBold}) format("truetype");
}

@font-face {
  font-family: "regular";
  src: url(${StolzlBook}) format("truetype");
}

@font-face {
  font-family: "thin";
  src: url(${StolzlThin}) format("truetype");
}

@font-face {
  font-family: 'nav';
  src: url(${StolzlBook}) format("truetype");
}

* {
  padding: 0px;
  margin: 0px;
  font-family: "regular", sans-serif;
  font-size: ${({ theme }) => theme.size.md};
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.sm};
  }
}

html,
body {
  scroll-behavior: smooth;
  background-color: ${({ theme }) => theme.color.background};
}

.Toastify {
  * {
  font-size: ${({ theme }) => theme.size.sm};
  }
}

.main-content {
  min-height: 100vh;
}

tspan {
	font-size: 14px !important;
	color: black !important;
	font-family:  Arial;
}

`

export default GlobalStyles
