import Container from '../UI/Container/Container'
import { Header, StyledAGB, H2, P, Content } from './AGB.Styled'
import Layout from '../UI/Layout/Layout'
import { useSelector } from 'react-redux'

const AGB = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <Layout>
      <StyledAGB>
        <Container>
          <Content>
            <Header>{translation.agb.headline}</Header>
            <P>
              Allgemeine Geschäftsbedingungen Bayern-Gold24 für die Internetplattform www.bayern-gold24.de
              <br />
              Jede hier bezeichnete Tätigkeit von Bayern-Gold24, Inh. Monika Demirel, Luitpold-Leusser-Platz, 1, 86825 Bad Wörishofen (nachfolgend
              Bayern-Gold24) erfolgt auf Grundlage der anbei stehenden Allgemeinen Geschäftsbedingungen (nachfolgend AGB), die allen Kunden im Shop
              auf der Internetplattform www.bayern-gold24.de (nachfolgend Internetplattform) gegenüber durch Aushändigung bzw. Hinterlegung im Shop
              auf der Internetplattform bekannt gegeben werden und von diesem anerkannt werden.
            </P>
            <H2>1. Allgemeines</H2>
            <P>
              1.1Bayern-Gold24 verkauft über den Shop auf der Internetplattform Schmuck aller Art (nachfolgend Produkt). Bayern-Gold24 ermöglicht es
              gewerblichen Kunden i.S.d. § 14 BGB (nachfolgend gewerbliche Kunden) als auch Verbrauchern i.S.d. § 13 BGB als Endverbraucher
              (nachfolgend Endverbraucher) das vorbezeichnete Produkt zu erwerben.
            </P>
            <H2>2. Registrierung gewerblicher Kunden und Endverbraucher</H2>
            <P>
              2.1. Gewerbliche Kunde oder Endverbraucher bedürfen, um im Shop von Bayern-Gold24 Produkte einkaufen zu können, keiner vorherigen
              Registrierung mit der Internetplattform. Alle relevanten Daten sind im Laufe des Bestellvorganges einzugeben.
            </P>
            <H2>Zustandekommen eines Vertrages</H2>
            <P>
              3.1 Grundlage eines jeden Vertrages bilden die anbei stehenden AGB. Diese werden Bestandteil des vorbezeichneten Vertrags. Den AGB von
              gewerblichen Kunden und Endverbrauchern und dessen Einbeziehung in den vorbezeichneten Vertrag wird hiermit ausdrücklich widersprochen.
            </P>
            <P>
              3.2 Im Laufe der Nutzung des Shops von Bayern-Gold24 auf der Internetplattform erhält der gewerbliche Kunde oder Endverbraucher die
              Möglichkeit die dort dargestellten Produkte durch Klicken des Auswahl-Buttons für eine Bestellung auszuwählen oder sofort zu erwerben.
            </P>
            <P>3.4 Durch Übersendung einer Kaufbestätigung über die Internetplattform nimmt Bayern-Gold24 dieses Angebot an.</P>
            <P>
              3.5 Der dem mit Bayern-Gold24 geschlossenen Kaufvertrag zugrundeliegende Vertragsinformationen und Vertragstexte werden nach
              Vertragsschluss nicht auf der Internetplattform gespeichert. Gewerbliche Kunden und Endverbraucher sind dafür verantwortlich, die
              vorbezeichneten über die Internetplattform einsehbaren Informationen zu Zwecken der Beweisführung, Buchführung oder zu anderen Zwecken
              auf eine von der Internetplattform unabhängigen Speichermedium zu archivieren. Bayern-Gold24 speichert selbst keine Vertragstexte und
              macht diese auch nicht zugänglich.
            </P>
            <H2>4. Widerruf von Verbrauchern i.S.d. § 13 BGB innerhalb des Geltungsbereichs des BGB</H2>
            <P>
              4.1 Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen den mit Bayern-Gold24 geschlossenen Vertrag zu widerrufen. Die
              Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die
              Waren in Besitz genommen haben bzw. hat.
            </P>
            <P>
              4.2 Um sein Widerrufsrecht auszuüben, muss der Endverbraucher Bayern-Gold24, Luitpold-Leusser-Platz, 1, 86825 Bad Wörishofen, Telefon:
              +498247 9985208 , Fax: +498247 9985207 , E-Mail: info@bayern-gold24.de) mittels einer eindeutigen Erklärung (z.B. ein mit der Post
              versandter Brief oder E-Mail) von seinem Entschluss, diesen Vertrag zu widerrufen, informieren. Der Endverbraucher kann dafür das
              beigefügte Muster-Widerrufsformular verwenden (hier klicken), ist in der Nutzung aber frei. Zur Wahrung der Widerrufsfrist reicht es
              aus, dass der Endverbraucher die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absendet.
            </P>
            <P>
              4.3 Folgen des Widerrufs: Widerruft der Endverbraucher den Vertrag, so hat Bayern-Gold24 alle Zahlungen, die sie von dem Endverbraucher
              erhalten hat, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über den Widerruf
              dieses Vertrags bei der Bayern-Gold24 eingegangen ist. Für diese Rückzahlung wird dasselbe Zahlungsmittel verwendet, das bei der
              ursprünglichen Transaktion eingesetzt wurde, es sei denn, mit dem Endverbraucher wurde ausdrücklich etwas Anderes vereinbart. Wir können
              die Rückzahlung verweigern, bis wir die Waren wiederzurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren
              zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist. Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen
              vierzehn Tagen ab dem Tag, an dem Sie uns über den Wiederruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die
              Frist ist gewahrt, wenn Sie die Ware vor Ablauf der Frist von vierzehn Tagen absenden.
            </P>
            <P>
              4.4 Der Endverbraucher wird bereits jetzt darüber informiert, dass im Widerrufsfall die Kosten für die Rücksendung der Waren der
              Endverbraucher i.S.d. § 357 Abs. 6 BGB zu tragen hat.
            </P>
            <P>ENDE DER WIDERRUFSBELEHRUNG</P>
            <H2>Zahlungsbedingungen</H2>
            <P>
              5.1 Der Erwerb der Produkte über den Shop auf der Internetplattform erfolgt gegen Nutzung eines auf der Internetplattform näher
              bezeichneten Zahlungsmittels.
            </P>
            <P>
              5.2 Alle auf der Internetplattform ausgezeichneten Preise sind unverbindlich und können jederzeit von Bayern-Gold24 angepasst werden.
              Dies umfasst sowohl den eigentlichen Preis der Artikel als auch die zum Zeitpunkt des Vertragsschlusses gültige Mehrwertsteuer.
            </P>
            <H2>6. Lieferung</H2>

            <P>
              6.1 Bayern-Gold24 liefert innerhalb Deutschlands und in das europäische Ausland. Möglich ist auch eine Abholung der Ware durch den
              gewerblichen Kunden oder Endverbraucher.
            </P>

            <P>
              6.2 Das gekaufte Produkt wird sorgfältig verpackt und anschließend über einen Logistikpartner an den gewerblichen Kunden oder
              Endverbraucher versendet. Das Produkt wird versendet, sobald ein entsprechender Zahlungseingang des Kaufreises verzeichnet wurde. Die
              genaue Versandzeit ist der jeweiligen Artikelbeschreibung zu entnehmen. Die Versandkosten hängen von der Paketgröße ab und sind zu jedem
              Artikel ausgewiesen
            </P>

            <P>
              6.3 Der Versand der Ware erfolgt grundsätzlich an die vom gewerblichen Kunden oder Endverbraucher angegeben Adresse. Der gewerbliche
              Kunde oder Endverbraucher verpflichtet sich sicherzustellen, dass die von Ihm angegebenen Lieferadresse korrekt ist. Diesbezügliche
              Übertragungsfehler gehen zulasten des gewerblichen Kunden oder Endverbrauchers.
            </P>

            <P>
              6.4 Bei Abholung eines Produkts wird der gewerbliche Kunde oder Endverbraucher bereits jetzt darauf aufmerksam gemacht, dass das Produkt
              nicht ständig im Lager vorrätig ist. Das Produkt kann frühestens 24h nach dem Kauf abholen. Der gewerbliche Kunde oder Endverbraucher
              wird gebeten Bayern-Gold24 zu kontaktieren, damit ein Termin zur Übergabe des Produkts vereinbart werden kann.
            </P>

            <P>6.5 Bayern-Gold24 behält sich das Eigentum an der gelieferten Ware bis zur vollständigen Kaufpreiszahlung vor.</P>

            <P>
              6.6 Gegenüber gewerblichen Kunden behält sich Bayern-Gold24 das Eigentum an der gelieferten Sache bis zur vollständigen Zahlung
              sämtlicher Forderungen aus der Lieferbeziehung vor.
            </P>

            <P>
              6.7 Der gewerbliche Kunde ist zur Weiterveräußerung des Produkts im normalen Geschäftsverkehr berechtigt. Die Forderungen des Abnehmers
              aus der Weiterveräußerung der Vorbehaltsware tritt der gewerbliche Kunde schon jetzt an Bayern-Gold24 in Höhe des mit uns vereinbarten
              Faktura-Endbetrages einschließlich Mehrwertsteuer ab. Diese Abtretung gilt unabhängig davon, ob die Kaufsache ohne oder nach
              Verarbeitung weiterverkauft worden ist. Der gewerbliche Kunde bleibt zur Einziehung der Forderung auch nach der Abtretung ermächtigt.
              Die Befugnis von Bayern-Gold24, die Forderung selbst einzuziehen, bleibt davon unberührt. Bayern-Gold24 wird die Forderung nicht
              einziehen, solange der gewerbliche Kunde seinen Zahlungsverpflichtungen aus den vereinnahmten Erlösen nachkommt, nicht in Zahlungsverzug
              ist und insbesondere kein Antrag auf Eröffnung eines Insolvenzverfahrens gestellt ist oder Zahlungseinstellung vorliegt.
            </P>

            <P>
              6.8 Bayern-Gold24 schuldet dem gewerblichen Kunden nur die rechtzeitige, ordnungsgemäße Ablieferung der Ware an ein
              Transportunternehmen, für vom Transportunternehmen verursachte Verzögerungen und Beschädigungen ist von Bayern-Gold24 nicht
              verantwortlich.
            </P>

            <P>
              6.9 Gegenüber einem Endverbraucher gelten die gesetzlichen Bestimmungen des § 474 Abs. 4 i.V.m. 446 BGB. Die Gefahr des zufälligen
              Untergangs gegenüber einem Endverbraucher geht erst mit der Übergabe an den Endverbraucher über.
            </P>
            <H2>7. Mängelgewährleistung</H2>
            <P>
              7.1 Gewerblichen Kunden und Endverbrauchern steht eine Mängelgewährleistung zu. Diese wird nach Maßgabe des Gesetzes bei Gebrauchtware
              auf 12 Monate beschränkt. Gewerbliche Kunden und Endverbraucher werden vor dem Kauf auf diesen Umstand separat hingewiesen.
            </P>
            <P>
              7.2 Findet eine Nacherfüllung im Wege der Ersatzlieferung statt, so ist der gewerbliche Kunde bzw. Endverbraucher verpflichtet die zuvor
              gelieferte Ware innerhalb von 30 Tagen nach Erhalt der Ersatzlieferung an Bayern-Gold24 auf Kosten des gewerblichen Kunden oder
              Endverbrauchers zurückzusenden.
            </P>
            <H2>8. Gewährleistung bzgl. der Inhalte im Shop der Internetplattform</H2>
            <P>
              8.1 Bayern-Gold24 garantiert weder die Fehlerfreiheit noch die Vollständigkeit der im Shop auf der Internetplattform zur Verfügung
              gestellten Inhalte. Bayern-Gold24 ist berechtigt, die im Shop auf der Internetplattform dargestellten Inhalte jederzeit und ohne
              vorherige Ankündigung zu verändern.
            </P>
            <P>
              8.2 Die Inhalte auf der Internetplattform werden „wie besehen“, ohne Zusicherungen, Gewährleistungen oder andere Bedingungen
              bereitgestellt.
            </P>
            <H2>9. Datenschutz</H2>
            <P>9.1 Bzgl. des Datenschutzes wird auf die auf der Internetplattform hinterlegte Datenschutzerklärung verwiesen (hier klicken).</P>
            <H2>10. Urheberrechte und Marken</H2>
            <P>
              10.1 Alle Texte, Bilder, Grafiken, Ton-, Video- oder sonstige Bilddarstellungen und deren Anordnung im Shop auf der Internetplattform
              sind gesetzlich geschützt (Copyright © 2017 Bayern-Gold24, all rights reserved).
            </P>
            <P>
              10.2 Ohne ausdrückliche schriftliche Zustimmung von Bayern-Gold24 Demirel Monika dürfen die Inhalte des Shops auf der Internetplattform
              nicht zu gewerblichen Zwecken kopiert, verbreitet, verändert oder Dritten zugänglich gemacht werden. Im Falle einer gewerblichen Nutzung
              ohne die schriftliche Erlaubnis von Bayern-Gold24 verpflichtet sich der gewerbliche Kunde oder Endverbraucher eine wettbewerbsrechtliche
              Strafzahlung an Bayern-Gold24 zu leisten. Die Strafzahlung bemisst sich nach Schwere, Ausmaß und Gefährlichkeit der begangenen
              Zuwiderhandlung. Jeder Verstoß wird zivil- und strafrechtlich geahndet.
            </P>
            <P>
              10.3 Teilweise unterliegen einige im Shop auf der Internetplattform wiedergegebene Bilder dem Urheberrecht Dritter. Gewerbliche Kunden
              und Endverbraucher erklären sich bereit, auch diese zu achten und nicht zu verletzen.
            </P>
            <H2>11. Haftung</H2>
            <P>
              11.1 Bayern-Gold24 und mit dieser verbundene Dritte sind nicht haftbar gegenüber gewerblichen Kunden und Endverbrauchern für alle
              besonderen, zufälligen, direkten, indirekten oder Folgeschäden jeder Art oder Schäden jeder Art, die aus dem Verlust der Nutzung, von
              Daten oder Gewinnen entstehen, gleich, ob auf die Möglichkeit eines solchen Schadens hingewiesen wurde, der aufgrund oder in Verbindung
              mit der Nutzung des Shops auf der Internetplattform entstanden sein könnte.
            </P>
            <P>
              11.2 Bayern-Gold24 haftet unbeschränkt, soweit die Schadensursache auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung von
              Bayern-Gold24 oder eines gesetzlichen Vertreters oder Erfüllungsgehilfen beruht.
            </P>
            <P>
              11.3 Ebenfalls haftet Bayern-Gold24 für die leicht fahrlässige Verletzung von wesentlichen Pflichten. Dies sind Pflichten, deren
              Verletzung die Erreichung des Vertragszwecks gefährdet oder deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst
              ermöglicht und auf deren Einhaltung der Anbieter regelmäßig vertrauen kann. In einem solchen Fall haftet Bayern-Gold24 jedoch nur für
              den vorhersehbaren, vertragstypischen Schaden. Bayern-Gold24 haftet jedoch nicht für die leicht fahrlässige Verletzung anderer als der
              in den vorstehenden Sätzen genannten Pflichten.
            </P>
            <P>
              11.4 Sämtliche in diesen AGB aufgeführten Haftungsbeschränkungen gelten nicht im Falle einer Verletzung von Leben, Körper und
              Gesundheit, für einen Mangel nach Übernahme einer Garantie für die Beschaffenheit des Produktes und bei arglistig verschwiegenen
              Mängeln.
            </P>
            <P>
              11.5 Batteriegesetz
              <br />
              Im Zusammenhang mit dem Vertrieb von Batterien oder Akkus oder mit der Lieferung von Geräten -insbesondere Uhren-, die Batterien
              enthalten, sind wir verpflichtet, Endverbraucher gemäß der Batterieverordnung auf Folgendes hinzuweisen:
              <br />
              Batterien sollen und dürfen nicht in den Hausmüll gegeben werden. Sie sind zur Rückgabe gebrauchter Batterien als Endverbraucher
              gesetzlich verpflichtet. Sie können Altbatterien, die wir als Neubatterien im Sortiment führen oder geführt haben, nach Gebrauch in
              einer kommunalen Sammelstelle oder im Handel vor Ort unentgeltlich zurückgeben.
              <br />
              Sie können Batterien auch unentgeltlich in unserer Filiale zurückgeben.
              <br />
              Unser Vorlieferant ist seiner Meldepflicht beim Umweltbundesamt nachgekommen.
              <br />
              Batterien oder Akkus, die Schadstoffe enthalten, sind mit dem Symbol einer durchkreuzten Mülltonne gekennzeichnet. Das bedeutet, dass
              die Batterien oder Akkus nicht in den Hausmüll gegeben werden darf. In der Nähe des Mülltonnensymbols befindet sich die chemische
              Bezeichnung des Schadstoffes. Die weiteren abgebildeten Symbole haben folgende Bedeutung:
              <br />
              Pb = Batterie enthält mehr als 0,004 Masseprozent Blei
              <br />
              Cd = Batterie enthält mehr als 0,002 Masseprozent Cadmium
              <br />
              Hg = Batterie enthält mehr als 0,0005 Masseprozent Quecksilber.
              <br />
              Bitte beachten Sie die vorstehenden Hinweise.
              <br />
              Elektro- und Elektronikgeräten dürfen nicht über den Hausmüll entsorgt werden. Verbraucher können Ihre Elektro-Altgeräte kostenlos bei
              einer der kommunalen Sammelstellen abgeben.
            </P>
            <H2> 12. Änderung der AGB</H2>
            <P>
              12.1 Bayern-Gold24 behält sich das Recht vor, diese AGB jederzeit nach eigenem Ermessen durch Veröffentlichung einer aktualisierten
              Version der AGB auf der Internetplattform zu ändern.
            </P>
            <P>
              12.2 Alle Änderungen dieser AGBs gelten als genehmigt, wenn der gewerbliche Kunde oder Endverbraucher nicht innerhalb von 30 Tagen nach
              Erhalt der Mitteilung der Änderung der AGB widerspricht.
            </P>
            <P>
              12.3 Sollte der gewerbliche Kunde oder Endverbraucher trotz der geänderten AGB die Internetplattform nach Ablauf der Frist von 30 Tagen
              weiter nutzen, wird die Weiternutzung als bindende Zustimmung zu den Änderungen erachtet.
            </P>
            <H2>13. Sonstiges</H2>
            <P>
              13.1 Nichts in diesen Nutzungsbedingungen schafft oder verleiht Rechte oder andere Vorteile für Dritte, die nicht Teil der
              Geschäftsbeziehung zwischen den Parteien sind.
            </P>
            <P>
              13.2 Diese ABG stellen die gesamte Vereinbarung zwischen Bayern-Gold24 und dem gewerblichen Kunden oder Endverbraucher in Bezug den Shop
              von Bayern-Gold24 auf der Internetplattform dar und treten an die Stelle aller früheren Vereinbarungen den Parteien.
            </P>
            <H2>14. Geltendes Recht und Gerichtsstand</H2>
            <P>
              14.1 Diese AGB unterliegen, unter Ausschluss des UN-Kaufrechts sowie des internationalen Privatrechts, dem Gesetz der Bundesrepublik
              Deutschland und werden nach diesem ausgelegt. Als Gerichtsstand gilt, soweit eine vertragliche Gerichtsstandsvereinbarung zulässig ist,
              der Sitz von Bayern-Gold24 als vereinbart.
            </P>
            <P>
              14.2 Endverbraucher mit gewöhnlichem Aufenthalt im Ausland können sich, unabhängig von der konkreten Rechtswahl, stets auch auf das
              zwingende Verbraucherschutzrecht des Staates berufen, in dem sie ihren gewöhnlichen Aufenthalt haben.
            </P>
            <P>
              14.3 Alle Änderungen oder Ergänzungen zu diesem Vertrag bedürfen zu Ihrer Gültigkeit der Schriftform. Dies gilt auch für das
              Schriftformerfordernis an sich.
            </P>
            <P>
              14.4 Soweit einzelne Bestimmungen dieser AGB ganz oder teilweise unwirksam sind, wird die Wirksamkeit des Vertrages sowie der übrigen
              Bestimmungen hiervon nicht berührt. An die Stelle der unwirksamen oder undurchführbaren Bestimmung soll diejenige wirksame und
              durchführbare Regelung treten, deren Wirkungen der wirtschaftlichen Zielsetzung am nächsten kommen, die die Vertragsparteien mit der
              unwirksamen bzw. undurchführbaren Bestimmung verfolgt haben.
            </P>
            <P>
              14.5 Die Europäische Kommission stellt unter http://ec.europa.eu/consumers/odr/ eine Plattform zur Online-Streitbeilegung bereit. Diese
              Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten aus Online-Kauf oder Dienstleistungsverträgen, an
              denen ein Verbraucher beteiligt ist.
            </P>
            <P>
              14.6 Bayern-Gold24 informiert alle Verbraucher hiermit, dass es nach dem Gesetz über die alternative Streitbeilegung in
              Verbrauchersachen (VSBG) nicht bereit oder verpflichtet ist an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen noch an einem solchen gegenwärtig teilnimmt.
            </P>
            <P>Stand: Februar 2018</P>
            <H2>Alternative Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO und § 36 VSBG:</H2>
            <P>
              Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die du unter https://ec.europa.eu/consumers/odr
              findest. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht
              bereit.
            </P>
          </Content>
        </Container>
      </StyledAGB>
    </Layout>
  )
}

export default AGB
