import styled from 'styled-components'

export const StyledLayout = styled.div``

export const CoreContent = styled.div`
  min-height: 100vh;
  /* min-height: calc(
    100vh - ${({ hideHeader }) => (hideHeader ? '0px' : '80px')}
  ); */
  padding-top: ${({ hideHeader }) => (hideHeader ? '0px' : '120px')};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding-top: 0px;
  }
`
