import { StyledWeddingRingConfigurator, Headline, CustomText } from './WeddingRingConfigurator.Styled'
import Layout from '../UI/Layout/Layout'
import Container from '../UI/Container/Container'
import Hero from '../UI/Hero/Hero'
import heroImage from '../../assets/image/weddingRingConfigurator/hero.jpg'
import { useSelector } from 'react-redux'
import Contact from '../ContactSection/ContactSection'

const WeddingRingConfigurator = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <Layout>
      <Hero img={heroImage} />
      <StyledWeddingRingConfigurator>
        <Container>
          <Headline text={translation.weddingRingConfigurator.title} />
          <CustomText>
            Trauringkauf braucht persönliche Fachberatung. Aber Zuhause können Sie sich Ihre Trau(m)ringe vorab in Ruhe auswählen. Mit dem{' '}
            <a href="https://konfigurator.jakob-schmuck.de/configurator" target={'_blank'} rel={'noreferrer'}>
              Simon & Söhne Trauring Konfigurator.
            </a>
          </CustomText>
          <CustomText>
            Er bietet Ihnen eine umfassende Auswahl an Trauringen des bedeutenden Trauring-Produzenten Simon & Söhne. Beim Kauf von Trauringen gibt es
            aber einiges zu beachten, wie z.B. die Passform, Farbe, Form.
          </CustomText>
          <CustomText>
            Unser Tipp: Suchen Sie sich deshalb Ihre Ringe über den{' '}
            <a href="https://konfigurator.jakob-schmuck.de/configurator" target={'_blank'} rel={'noreferrer'}>
              Simon & Söhne Trauring Konfigurator
            </a>{' '}
            in Ruhe aus. Speichern oder notieren Sie dann das Modell und die Größen, und kommen Sie bei uns zur abschließenden Beratung vorbei. Hier
            klicken , dann öffnet sich der Konfigurator in einem neuen Fenster.
          </CustomText>
          <CustomText style={{ fontFamily: 'bold' }}>Wir freuen uns auf Sie!</CustomText>
        </Container>
      </StyledWeddingRingConfigurator>
      <Contact />
    </Layout>
  )
}

export default WeddingRingConfigurator
