const _ = require('lodash')

const theme = {
  size: {
    sm: '16px',
    md: '22px',
    lg: '30px',
    xlg: '38px',
  },
  color: {
    font: {
      primary: 'black',
      secondary: 'white',
      gold: 'rgb(202,177,126)',
      danger: '#e60005',
    },
    button: {
      background: '#e60005',
      font: '#ffffff',
    },
    input: {
      background: '#ffffff',
      font: '#000000',
      border: '#ffffff',
    },
    background: '#f9e1e1',
    backgroundLight: '#f1f1f180',
    backgroundRed: 'rgb(48,26,26)',
    backgroundWhite: '#ffffff',
    backgroundDark: 'rgb(38,28,27)',
    border: 'rgb(194,194,194)',
    primary: '#8e1c21',
    secondary: 'rgb(236,237,242)',
    danger: '#e60005',
  },
  breakpoint: {
    sm: '768px',
    md: '992px',
    lg: '1200px',
  },
  border: {
    radius: '5px',
  },
  shadow: '2px 2px 16px -1px rgba(0,0,0,0.48)',
}

const getTheme = (originTheme = {}) => _.merge({}, theme, originTheme)

module.exports = getTheme
