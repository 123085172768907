import Container from '../UI/Container/Container'
import { StyledServicesSection, Row, Headline, Outro, OutroItem } from './ServicesSection.Styled'
import Service from './Service/Service'
import { useSelector } from 'react-redux'
import coinIcon from '../../assets/image/services-section/coin.png'
import diamondIcon from '../../assets/image/services-section/diamond.png'
import chainIcon from '../../assets/image/services-section/chain.png'
import goldIcon from '../../assets/image/services-section/gold.png'
import ringIcon from '../../assets/image/services-section/ring.png'
import watchIcon from '../../assets/image/services-section/watch.png'

const ServicesSection = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <StyledServicesSection>
      <Headline text={translation.servicesSection.title} />
      <Container>
        <Row>
          <Service img={ringIcon} data={translation.servicesSection.services.jewelry} />
          <Service img={diamondIcon} data={translation.servicesSection.services.diamant} />
        </Row>
        <Row>
          <Service img={watchIcon} data={translation.servicesSection.services.watches} />
          <Service img={goldIcon} data={translation.servicesSection.services.investmentGold} />
        </Row>
        <Row>
          <Service img={coinIcon} data={translation.servicesSection.services.numismatik} />
          <Service img={chainIcon} data={translation.servicesSection.services.toothGold} />
        </Row>
      </Container>
      <Outro>
        {translation.servicesSection.outro.map((text, index) => (
          <OutroItem key={index} dangerouslySetInnerHTML={{ __html: text }} />
        ))}
      </Outro>
    </StyledServicesSection>
  )
}

export default ServicesSection
