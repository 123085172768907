import styled from 'styled-components'
import Text from '../UI/Text/Text'

export const StyledServicesSection = styled.div`
  padding: 100px 0;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 50px 0%;
  gap: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    gap: 25px;
    margin: 25px 0;
    flex-wrap: wrap;
  }
`

export const Headline = styled(Text)`
  font-size: 75px;
  text-align: center;
  font-family: 'bold';
  margin-bottom: 100px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 50px;
    margin-bottom: 50px;
  }
`

export const Outro = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  text-align: center;
  margin-top: 75px;
`
export const OutroItem = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  margin-top: 10px;
  strong,
  u,
  a {
    font-size: ${({ theme }) => theme.size.sm};
    color: ${({ theme }) => theme.color.primary};
    font-family: 'bold';
  }
`
