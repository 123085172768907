import { useEffect } from 'react'
import { useState } from 'react'
import { StyledImageCarousel, Image } from './ImageCarousel.Styled'

const ImageCarousel = ({ images }) => {
  const [active, setActive] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((active) => (active + 1 >= images.length ? 0 : active + 1))
    }, 5000)
    return () => clearInterval(interval)
  }, [images])

  return (
    <StyledImageCarousel>
      {images.map((image, index) => {
        return <Image className={active === index ? 'active' : ''} src={image} key={index} />
      })}
    </StyledImageCarousel>
  )
}

export default ImageCarousel
