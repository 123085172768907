import { useSelector } from 'react-redux'
import { StyledContactSection, Title, CTA } from './ContactSection.Styled'

const Contact = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <StyledContactSection>
      <Title text={translation.contactSection.title} />
      <CTA href="/contact">{translation.contactSection.cta}</CTA>
    </StyledContactSection>
  )
}

export default Contact
