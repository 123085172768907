import styled from 'styled-components'
import Image from '../../UI/Image/Image'

export const StyledHero = styled.div`
  height: calc(100vh - 120px);
  width: 100%;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: top center;
  position: relative;
  overflow: hidden;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-image: ${({ mobileImg }) => mobileImg && `url(${mobileImg});`};
  }
`

export const MobileImage = styled(Image)`
  width: 100%;
`

export const VideoWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`

export const ContentWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 2;
`
