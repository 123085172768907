import { StyledHome, HeroContent, CustomText, Headline, Highlight, Subheadline, TextHeadline, TextWrapper, Signature, Logo } from './Home.Styled'
import Layout from '../UI/Layout/Layout'
import Hero from './Hero/Hero'
import Container from '../UI/Container/Container'
// import heroVideo from '../../assets/image/home/5.mp4'
import DSC02337 from '../../assets/image/home/heros/DSC02337.jpg'
import DSC02362 from '../../assets/image/home/heros/DSC02362.jpg'
import DSC05440 from '../../assets/image/home/heros/DSC05440.jpg'
import DSC05471 from '../../assets/image/home/heros/DSC05471.jpg'
import heroMobileImage from '../../assets/image/home/hero-mobile.png'
import logo from '../../assets/image/logo.png'
import { useSelector } from 'react-redux'
import { getUUID } from '../../utility'
import Chart from './Chart/Chart'
import Services from '../ServicesSection/ServicesSection'
import Contact from '../ContactSection/ContactSection'
import Shop from '../ShopSection/ShopSection'
import PurchaseCTA from '../PurchaseCTA/PurchaseCTA'

const Home = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <Layout>
      <StyledHome>
        <Hero images={[DSC02337, DSC02362, DSC05440, DSC05471]} mobileImg={heroMobileImage}>
          <HeroContent>
            <Logo src={logo} />
            <Headline>
              <span>{translation.home.hero.headline.part1}</span>
              <Highlight>{translation.home.hero.headline.part2}</Highlight>
              <span>{translation.home.hero.headline.part3}</span>
            </Headline>
            <Subheadline text={translation.home.hero.subheadline} />
            <PurchaseCTA />
          </HeroContent>
        </Hero>
        <Container>
          <TextWrapper>
            {translation.home.text.sections.map(({ headline, parts }) => {
              return (
                <div key={getUUID()}>
                  <TextHeadline text={headline} />
                  {parts.map((part) => (
                    <CustomText key={getUUID()} dangerouslySetInnerHTML={{ __html: part }} />
                  ))}
                </div>
              )
            })}
            <Signature margin="25px 0 0 0" text={translation.home.text.signature1} />
            <Signature text={translation.home.text.signature2} />
          </TextWrapper>
        </Container>
        <Chart />
        <Services />
        <Shop />
        <Contact />
      </StyledHome>
    </Layout>
  )
}

export default Home
