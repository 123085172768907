import { StyledHeader, StyledToggle, Navigation, CustomLink, NavItems, Item, MobileItem, ItemWrapper, Logo, MainLogo } from './Header.Styled'
import burger from '../../../../assets/image/burger.png'
import logo from '../../../../assets/image/logo.png'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, matchRoutes, useLocation } from 'react-router-dom'
import { getUUID } from '../../../../utility'

const Header = () => {
  const translation = useSelector((s) => s.translation)
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  const location = useLocation()
  const navItems = [
    {
      route: '',
      translation: translation.header.nav.home,
      visibleOnDesktop: true,
    },
    {
      route: 'purchase',
      translation: translation.header.nav.purchase,
      visibleOnDesktop: true,
    },
    {
      route: 'wedding-ring-configurator',
      translation: translation.header.nav.weddingRingConfigurator,
      visibleOnDesktop: true,
    },
    {
      route: 'gold-alloys',
      translation: translation.header.nav.goldAlloys,
      visibleOnDesktop: true,
    },
    {
      route: 'contact',
      translation: translation.header.nav.contact,
      visibleOnDesktop: true,
    },
    {
      route: 'impressum',
      translation: translation.header.nav.imprint,
      visibleOnDesktop: false,
    },
    {
      route: 'datenschutzbelehrung',
      translation: translation.header.nav.dataProtection,
      visibleOnDesktop: false,
    },
  ]

  const matchesRoute = (path) => {
    const matchArray = matchRoutes([{ path }], location)
    return !!matchArray
  }

  return (
    <StyledHeader>
      <ItemWrapper>
        <MainLogo src={logo} onClick={() => navigate('/')} />
        <NavItems>
          {navItems
            .filter((i) => i.visibleOnDesktop)
            .map(({ img, translation, route }) => {
              if (img) {
                return (
                  <Item key={getUUID()}>
                    <Logo src={img} onClick={() => navigate('/')} />
                  </Item>
                )
              }
              return (
                <Item key={getUUID()}>
                  <CustomLink active={matchesRoute(`/${route}`)} text={translation} onClick={() => navigate(`/${route}`)} />
                </Item>
              )
            })}
        </NavItems>
      </ItemWrapper>
      <StyledToggle open={open} src={burger} alt="Menu" onClick={() => setOpen(!open)} />
      {open && (
        <Navigation>
          {navItems.map(({ translation, route }) => {
            return (
              <MobileItem key={getUUID()}>
                <CustomLink active={matchesRoute(`/${route}`)} text={translation} onClick={() => navigate(`/${route}`)} />
              </MobileItem>
            )
          })}
        </Navigation>
      )}
    </StyledHeader>
  )
}

export default Header
