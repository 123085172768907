import { StyledLayout, CoreContent } from "./Layout.Styled";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ResponsiveState from "./ResponsiveState/ResponsiveState";
import Secure from "./Secure/Secure";

const Layout = ({
  children,
  className,
  hideHeader = false,
  authRequired = false,
}) => {
  return (
    <StyledLayout className={className}>
      <Secure authRequired={authRequired}>
        <ResponsiveState />
        {!hideHeader && <Header />}
        <CoreContent hideHeader={hideHeader}>{children}</CoreContent>
        <Footer />
      </Secure>
    </StyledLayout>
  );
};

export default Layout;
