import styled from 'styled-components'
import Link from '../../../UI/Link/Link'
import Text from '../../../UI/Text/Text'

export const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundRed};
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    /* display: none; */
  }
`

export const Content = styled.div`
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    padding: 15px;
  }
`

export const Column = styled.div`
  flex: 1 1 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    flex: 0 0 100%;
    margin-bottom: 30px;
  }
`

export const ImageFlex = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 30px;
  justify-content: left;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 25px;
    margin-top: 15px;
  }
`
export const CustomFlex = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 30px;
  justify-content: right;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }
`

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.color.secondary};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.size.sm};
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: calc(50vw - 20px);
    display: ${({ hiddenOnMobile }) => (hiddenOnMobile ? 'none' : 'block')};
    text-align: center;
  }
`

export const Copyright = styled(Text)`
  font-size: 12px;
  text-align: center;
  color: ${({ theme }) => theme.color.secondary};
  margin: 40px 0 0 0;
`

export const LocationHeadline = styled(Text)`
  color: ${({ theme }) => theme.color.secondary};
  font-family: 'bold';
  font-size: ${({ theme }) => theme.size.sm};
`
export const LocationText = styled.div`
  color: ${({ theme }) => theme.color.secondary};
  font-size: ${({ theme }) => theme.size.sm};
  a {
    font-size: ${({ theme }) => theme.size.sm};
    color: ${({ theme }) => theme.color.font.gold};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

export const SocialIconLink = styled.a`
  display: inline-block;
  margin-top: 15px;
`
