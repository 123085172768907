import { useSelector } from "react-redux";
import shopIcon from "../../assets/image/shopSection/shopIcon.jpeg";
const {
  StyledShopSection,
  Headline,
  Content,
  Line,
  ImageWrapper,
  ShopIcon,
} = require("./ShopSection.Styled");

const Shop = () => {
  const translation = useSelector((s) => s.translation);

  return (
    <StyledShopSection>
      <Headline text={translation.shopSection.title} />
      <Content>
        <Line />
        <ImageWrapper>
          <a
            href="https://www.berndwolf.de/partner/Bayern-Gold24BadW%C3%B6rishofen"
            target={"_blank"}
            rel={"noreferrer"}
          >
            <ShopIcon src={shopIcon} />
          </a>
        </ImageWrapper>
      </Content>
    </StyledShopSection>
  );
};

export default Shop;
