import styled from 'styled-components'
import Button from '../UI/Button/Button'

export const StyledConsent = styled.div`
  &.hidden {
    display: none;
  }
  background-color: $black-background;
  position: fixed;
  z-index: 10;
  height: 100%;
  width: 100%;
  text-align: center;
`

export const Content = styled.div`
  display: inline-block;
  width: 60%;
  margin-top: 10%;
  border-radius: ${({ theme }) => theme.border.radius};
  background-color: ${({ theme }) => theme.color.backgroundWhite};
  box-shadow: 0px 0px 10px rgba(168, 168, 168, 1);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 90%;
    margin-top: 5%;
  }
`

export const Headline = styled.div`
  padding: 15px;
  font-size: 20px;
  background-color: ${({ theme }) => theme.color.backgroundWhite};
  text-align: center;
`

export const Text = styled.div`
  padding: 15px;
  font-size: 14px;
`

export const Interaction = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.color.backgroundWhite};
  color: ${({ theme }) => theme.color.secondary};
  display: flex;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`

export const ButtonWrapper = styled.div`
  width: 50%;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    &:first-child {
      margin-bottom: 10px;
    }
    width: 100%;
  }
`
export const CustomButton = styled(Button)`
  font-size: 18px;
  color: ${({ theme }) => theme.color.secondary};
  outline: none;
  box-shadow: none;
  border: none;
  &.decline {
    color: ${({ theme }) => theme.color.primary};
    background-color: transparent;
  }
`
