import styled from 'styled-components'
import Text from '../UI/Text/Text'

export const StyledContactSection = styled.div`
  padding: 150px 0;
  text-align: center;
  background-color: ${({ theme }) => theme.color.backgroundDark};
`

export const Title = styled(Text)`
  text-align: center;
  font-size: 75px;
  font-family: 'bold';
  color: ${({ theme }) => theme.color.font.secondary};
  margin-bottom: 75px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 50px;
  }
`

export const CTA = styled.a`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.font.secondary};
  /* font-size: ${({ theme }) => theme.size.md}; */
  padding: 15px 50px;
  text-decoration: none;
  border-radius: ${({ theme }) => theme.border.radius};
`
