import { StyledGoldAlloys, Headline, CustomText, Split, ImageContainer, CustomImage } from './GoldAlloys.Styled'
import Layout from '../UI/Layout/Layout'
import Container from '../UI/Container/Container'
import Hero from '../UI/Hero/Hero'
import heroImage from '../../assets/image/goldAlloys/gold.jpg'
import image333 from '../../assets/image/goldAlloys/333.png'
import image585 from '../../assets/image/goldAlloys/585.png'
import image750 from '../../assets/image/goldAlloys/750.png'
import imageGold333 from '../../assets/image/goldAlloys/333.jpeg'
import imageGold375 from '../../assets/image/goldAlloys/375.jpeg'
import imageGold585 from '../../assets/image/goldAlloys/585.jpeg'
import imageGold750 from '../../assets/image/goldAlloys/750.jpeg'
import imageGold900 from '../../assets/image/goldAlloys/900.jpeg'
import imageGold916 from '../../assets/image/goldAlloys/916.jpeg'
import imageGold986 from '../../assets/image/goldAlloys/986.jpeg'
import imageGold999 from '../../assets/image/goldAlloys/999.jpeg'

import { useSelector } from 'react-redux'
import Contact from '../ContactSection/ContactSection'

const GoldAlloys = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <Layout>
      <Hero img={heroImage} />
      <StyledGoldAlloys>
        <Container>
          <Headline text={translation.goldAlloys.title} />
          <Split>
            <ImageContainer>
              <CustomImage src={image333} maxHeight="100%" />
            </ImageContainer>
            <CustomText>
              333er Schmuckgold 8K
              <br />
              33,3 % Goldgehalt – Rest meist Silber und/oder Kupfer
            </CustomText>
          </Split>
          <Split>
            <ImageContainer>
              <CustomImage src={image585} maxHeight="100%" />
            </ImageContainer>
            <CustomText>
              585er Schmuckgold 14K
              <br />
              58,5 % Goldgehalt – Rest meist Silber und/oder Kupfer
            </CustomText>
          </Split>
          <Split>
            <ImageContainer>
              <CustomImage src={image750} maxHeight="100%" />
            </ImageContainer>
            <CustomText>
              750er Schmuckgold 18K
              <br />
              75 % Goldgehalt – Rest meist Silber und/oder Kupfer
            </CustomText>
          </Split>
          <Headline text={translation.goldAlloys.overview} />
          <Split>
            <ImageContainer>
              <CustomImage src={imageGold333} maxHeight="100%" />
            </ImageContainer>
            <CustomText>
              33,3% Goldanteil.
              <br />
              Restbestandteile meist Kupfer, Silber, Nickel.
              <br />
              Je nach Verhältnis entsteht Gelbgold, Rotgold oder Weißgold.
            </CustomText>
          </Split>
          <Split>
            <ImageContainer>
              <CustomImage src={imageGold375} maxHeight="100%" />
            </ImageContainer>
            <CustomText>
              37,5% Goldanteil.
              <br />
              Restbestandteile meist Kupfer, Silber, Nickel.
              <br />
              Je nach Verhältnis entsteht Gelbgold, Rotgold oder Weißgold.
            </CustomText>
          </Split>
          <Split>
            <ImageContainer>
              <CustomImage src={imageGold585} maxHeight="100%" />
            </ImageContainer>
            <CustomText>
              58,5% Goldanteil.
              <br />
              Restbestandteile meist Kupfer, Silber, Nickel.
              <br />
              Je nach Verhältnis entsteht Gelbgold, Rotgold oder Weißgold.
            </CustomText>
          </Split>
          <Split>
            <ImageContainer>
              <CustomImage src={imageGold750} maxHeight="100%" />
            </ImageContainer>
            <CustomText>
              75 % Goldanteil.
              <br />
              Restbestandteile meist Kupfer, Silber, Nickel.
              <br />
              Je nach Verhältnis entsteht Gelbgold, Rotgold oder Weißgold.
            </CustomText>
          </Split>
          <Split>
            <ImageContainer>
              <CustomImage src={imageGold900} maxHeight="100%" />
            </ImageContainer>
            <CustomText>
              90 % Goldanteil.
              <br />
              Z.B. die Reichsgoldmark.
            </CustomText>
          </Split>
          <Split>
            <ImageContainer>
              <CustomImage src={imageGold916} maxHeight="100%" />
            </ImageContainer>
            <CustomText>
              91,6 % Goldanteil.
              <br />
              Z.B. der Krügerrand.
            </CustomText>
          </Split>
          <Split>
            <ImageContainer>
              <CustomImage src={imageGold986} maxHeight="100%" />
            </ImageContainer>
            <CustomText>
              98,6 % Goldanteil.
              <br />
              Z.B. Dukatengold.
            </CustomText>
          </Split>
          <Split>
            <ImageContainer>
              <CustomImage src={imageGold999} maxHeight="100%" />
            </ImageContainer>
            <CustomText>
              99,9 % Goldanteil.
              <br />
              Z.B. Feingold.
            </CustomText>
          </Split>
        </Container>
      </StyledGoldAlloys>
      <Contact />
    </Layout>
  )
}

export default GoldAlloys
