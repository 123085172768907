import styled from 'styled-components'

export const StyledAGB = styled.div`
  font-family: $text-font;
  color: ${({ theme }) => theme.color.font.primary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    * {
      font-size: 13px;
    }
  }
`

export const Anchor = styled.a`
  color: ${({ theme }) => theme.color.font.primary};
  word-break: break-word;
`
export const Header = styled.h1`
  font-size: 40px;
  margin-bottom: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 30px;
  }
`

export const Strong = styled.span`
  font-family: 'bold';
`
export const P = styled.p`
  margin: 15px 0px;
`

export const H3 = styled.h3`
  margin: 20px 0 15px 0;
  font-family: 'bold';
`
export const H2 = styled.h2`
  font-family: 'bold';
  margin: 20px 0 15px 0;
`

export const Content = styled.div`
  margin: 50px auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 0 15px;
  }
`
