import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { setToken } from '../../../../reducer/action/token'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { resolveToken } from '../../../../interface/auth'

const Secure = ({ children, authRequired = true }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const [isEvaluated, setIsEvaluated] = useState(false)

  useEffect(() => {
    const cookies = new Cookies()
    const token = cookies.get('token', { path: '/' })

    const evaluate = async () => {
      let status = false
      if (token) {
        status = await resolveToken(token)
      }
      if (status) {
        dispatch(setToken(token))
        setIsEvaluated(true)
      } else if (authRequired) {
        cookies.remove('token', { path: '/' })
        navigate('/')
      } else {
        setIsEvaluated(true)
      }
    }

    if (!isEvaluated) {
      evaluate()
    }
  }, [isEvaluated, authRequired, dispatch, navigate, pathname, searchParams])

  return isEvaluated ? <>{children}</> : <></>
}

export default Secure
