import { useSelector } from 'react-redux'
import { StyledPurchaseCTA } from './PurchaseCTA.Styled'
import Image from '../UI/Image/Image'
import arrow from '../../assets/image/home/arrow.png'

const PurchaseCTA = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <StyledPurchaseCTA href="https://ankauf.bayern-gold24.de/" target="_blank">
      {translation.home.hero.purchaseCTA}
      <Image src={arrow} height="15px;" margin="0 0 0 10px" />
    </StyledPurchaseCTA>
  )
}

export default PurchaseCTA
