import styled from 'styled-components'

export const StyledPurchase = styled.div`
  padding: 50px 0px;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.size.lg};
  font-family: 'bold';
  margin-top: 50px;
`

export const Strong = styled.span`
  font-family: 'bold';
`
export const PointHeadline = styled.div`
  font-family: 'bold';
  margin: 15px 0;
`

export const CustomText = styled.div`
  margin: 15px 0px;
  a {
    color: ${({ theme }) => theme.color.font.gold};
    text-decoration: none;
    &:hover {
      font-weight: bold;
    }
  }
`

export const CTAWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const PointImage = styled.div`
  height: 200px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  margin-top: 25px;
  width: 100%;
`

export const ImageSplit = styled.div`
  display: flex;
  gap: 15px;
`
