import styled from 'styled-components'
import Text from '../../UI/Text/Text'

export const StyledChart = styled.div`
  display: flex;
  position: relative;
`

export const Headline = styled(Text)`
  font-size: 75px;
  font-family: 'bold';
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
  margin-bottom: 100px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 50px;
  }
`

export const ChartWrapper = styled.div`
  flex: 1 1 0px;
  background-color: ${({ theme }) => theme.color.backgroundDark};
  padding: 100px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 0px;
  }
`

export const SideImage = styled.div`
  width: 30%;
  height: inherit;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`

export const StyledTooltip = styled.div`
  background-color: rgb(50, 50, 50);
  box-shadow: ${({ theme }) => theme.shadow};
  padding: 15px;
  border-radius: 20px;
`

export const Data = styled.div`
  display: flex;
  margin: 5px 0;
  width: 300px;
`

export const Key = styled.div`
  color: ${({ color }) => color};
  font-size: ${({ theme }) => theme.size.sm};
  flex: 1 1 0px;
`
export const Value = styled.div`
  flex: 1 1 0px;
  text-align: right;
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.size.sm};
`
