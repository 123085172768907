import styled from 'styled-components'
import Image from '../UI/Image/Image'
import Text from '../UI/Text/Text'

export const StyledShopSection = styled.div`
  padding: 100px 0;
  background-color: ${({ theme }) => theme.color.primary};
`

export const Headline = styled(Text)`
  font-size: 75px;
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 50px;
  }
`

export const Content = styled.div`
  height: 35vh;
  margin-top: 25px;
  position: relative;
`

export const Line = styled.div`
  height: 1px;
  position: absolute;
  top: 50%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.backgroundWhite};
  z-index: 0;
`

export const ImageWrapper = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ShopIcon = styled(Image)`
  background-color: ${({ theme }) => theme.color.primary};
  padding: 30px;
  height: 350px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 150px;
  }
`
