import styled from 'styled-components'
import Image from '../UI/Image/Image'
import Text from '../UI/Text/Text'

export const StyledHome = styled.div``

export const HeroContent = styled.div`
  text-align: center;
`

export const Headline = styled.div`
  * {
    font-size: 70px;
    font-family: 'bold';
    color: ${({ theme }) => theme.color.font.secondary};
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.54);
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: 40px;
    }
  }
`

export const Highlight = styled.span`
  color: ${({ theme }) => theme.color.font.gold};
`

export const Subheadline = styled(Text)`
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.54);
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: center;
  margin: 50px 0px;
`
export const TextHeadline = styled(Text)`
  color: ${({ theme }) => theme.color.primary};
  margin-top: 25px;
`

export const TextWrapper = styled.div`
  margin: 75px 0px;
`

export const Signature = styled(Text)`
  margin: ${({ margin }) => margin || '0'};
  font-family: 'bold';
`

export const Logo = styled(Image)`
  height: 300px;
  margin: 0 0 50px 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 150px;
  }
`

export const CustomText = styled.div`
  margin: 25px 0 0 0;
`
