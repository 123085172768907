import styled from 'styled-components'

export const StyledPurchaseCTA = styled.a`
  display: inline-block;
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.font.secondary};
  padding: 25px;
  /* font-family: "headline"; */
  border-radius: ${({ theme }) => theme.border.radius};
  font-size: ${({ theme }) => theme.size.md};
  text-decoration: none;
  transition: all 0.5s;
  &:hover {
    box-shadow: 2px 2px 16px -1px rgba(255, 255, 255, 0.48);
  }
`
