import { StyledHero, VideoWrapper, ContentWrapper } from './Hero.Styled'
import ScrollToggle from './ScrollToggle/ScrollToggle'
import ImageCarousel from './ImageCarousel/ImageCarousel'

const Hero = ({ images, video, mobileImg, children, className }) => {
  return (
    <StyledHero mobileImg={mobileImg} className={className}>
      <VideoWrapper>
        <video width="100%" height="100%" autoPlay={true} muted playsInline loop>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </VideoWrapper>
      <ImageCarousel images={images} />
      <ContentWrapper>{children}</ContentWrapper>
      <ScrollToggle />
    </StyledHero>
  )
}

export default Hero
