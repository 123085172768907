import { CustomText, Headline, StyledContact, Highlight } from './Contact.Styled'
import Hero from '../UI/Hero/Hero'
import heroImage from '../../assets/image/contact/hero.webp'
import { useSelector } from 'react-redux'
import Layout from '../UI/Layout/Layout'
import Container from '../UI/Container/Container'
import Image from '../UI/Image/Image'
import googleMapsImage from '../../assets/image/contact/google_maps.png'

const Contact = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <Layout>
      <Hero img={heroImage} />
      <StyledContact>
        <Container>
          <Headline text={translation.contact.title} />
          <CustomText>Sie erreichen Bayern-Gold24 immer ganz einfach:</CustomText>
          <Highlight>Bad Wörishofen</Highlight>
          <CustomText>
            Luitpold-Leusser-Platz 1<br />
            D-86825 Bad Wörishofen
          </CustomText>
          <a
            href="https://www.google.com/maps/place/Luitpold-Leusser-Platz+1,+86825+Bad+W%C3%B6rishofen/@48.0061661,10.5922862,17z/data=!3m1!4b1!4m5!3m4!1s0x479c1196e2b60b4f:0xdf8c8c7911e5afa6!8m2!3d48.0061625!4d10.5944749"
            target={'_blank'}
            rel={'noreferrer'}
          >
            <Image height="30px" src={googleMapsImage} />
          </a>
          <CustomText>
            1. Im Festnetz unter der Telefonnummer <a href="tel: 08247 9985208">08247 9985208</a>
            <br />
            2. Mobil unter der Rufnummer <a href="tel:0151 62664214">0151 62664214</a>
            <br />
            3. oder senden Sie eine E-Mail mit Ihrem Namen an <a href="mailto:info@bayern-gold24.de">info@bayern-gold24.de</a>
          </CustomText>
          <Highlight>Marktoberdorf im Uhren-Café</Highlight>
          <CustomText>
            Salzstraße 23
            <br />
            D-87616 Marktoberdorf
          </CustomText>
          <a
            href="https://www.google.com/maps/place/Salzstra%C3%9Fe+23,+87616+Marktoberdorf/@47.77733,10.6159714,17z/data=!3m1!4b1!4m5!3m4!1s0x479c68fa78e3e93d:0x3e25635de544152b!8m2!3d47.77733!4d10.61816"
            target={'_blank'}
            rel={'noreferrer'}
          >
            <Image height="30px" src={googleMapsImage} />
          </a>
          <CustomText>
            1. Im Festnetz unter der Telefonnummer <a href="tel: 08342-9199499">08342-9199499</a>
            <br />
            2. Mobil unter der Rufnummer <a href="tel:0151 62664214">0151 62664214</a>
            <br />
            3. oder senden Sie eine E-Mail mit Ihrem Namen an <a href="mailto:info@bayern-gold24.de">info@bayern-gold24.de</a>
          </CustomText>
        </Container>
      </StyledContact>
    </Layout>
  )
}

export default Contact
