import { useSelector } from 'react-redux'
import { StyledChart, Headline, ChartWrapper, SideImage, StyledTooltip, Data, Key, Value } from './Chat.Styled'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import jewleryImage from '../../../assets/image/home/gold2.jpg'
import { useEffect } from 'react'
import { chart } from '../../../interface/metal'
import { useState } from 'react'
import { formatPrice, getPrettyDate } from '../../../utility'

const Chart = () => {
  const translation = useSelector((s) => s.translation)
  const [data, setData] = useState(null)
  const goldColor = '#ebde80'
  const silverColor = '#dde2df'
  const platinumColor = '#bfc5c7'
  const palladiumColor = '#989f9e'

  useEffect(() => {
    const fetchMetals = async () => {
      const data = await chart()
      setData(data)
    }
    if (!data) {
      fetchMetals()
    }
  })

  const CustomTooltip = ({ payload }) => {
    if (payload.length > 0) {
      const data = payload[0].payload
      return (
        <StyledTooltip>
          <Data>
            <Key color={'white'}>
              {'Datum: '}
              {data.name}
            </Key>
          </Data>
          <Data>
            <Key color={goldColor}>{translation.generic.gold}</Key>
            <Value>{formatPrice(data.gold)}</Value>
          </Data>
          <Data>
            <Key color={silverColor}>{translation.generic.silver}</Key>
            <Value>{formatPrice(data.silver)}</Value>
          </Data>
          <Data>
            <Key color={platinumColor}>{translation.generic.platinum}</Key>
            <Value>{formatPrice(data.platinum)}</Value>
          </Data>
          <Data>
            <Key color={palladiumColor}>{translation.generic.palladium}</Key>
            <Value>{formatPrice(data.palladium)}</Value>
          </Data>
        </StyledTooltip>
      )
    }
    return <></>
  }

  const transformCentsToEuro = (data) => {
    return data.map((item) => {
      return {
        ...item,
        name: getPrettyDate(new Date(item.day)),
        Gold: item.gold / 100,
        Silber: item.silver / 100,
        Platin: item.platinum / 100,
        Palladium: item.palladium / 100,
      }
    })
  }

  if (!data) return <></>
  return (
    <StyledChart>
      <ChartWrapper>
        <Headline text={translation.home.chart.headline} />
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={600}
            data={transformCentsToEuro(data)}
            margin={{
              right: 50,
              left: 50,
            }}
          >
            <Legend wrapperStyle={{ top: -50, left: 25 }} />
            <CartesianGrid strokeDasharray="2 4" vertical={false} />
            <XAxis axisLine={{ stroke: 'white' }} stroke="white" dataKey="name" />
            <YAxis axisLine={{ stroke: 'white' }} stroke="white" />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="Gold"
              stroke={goldColor}
              strokeWidth={4}
              dot={{
                strokeWidth: 1,
                r: 3,
                fill: 'rgb(30,30,32)',
              }}
            />
            <Line
              type="monotone"
              dataKey="Silber"
              stroke={silverColor}
              strokeWidth={4}
              dot={{
                strokeWidth: 1,
                r: 3,
                fill: 'rgb(30,30,32)',
              }}
            />
            <Line
              type="monotone"
              dataKey="Platin"
              stroke={platinumColor}
              strokeWidth={4}
              dot={{
                strokeWidth: 1,
                r: 3,
                fill: 'rgb(30,30,32)',
              }}
            />
            <Line
              type="monotone"
              dataKey="Palladium"
              stroke={palladiumColor}
              strokeWidth={4}
              dot={{
                strokeWidth: 1,
                r: 3,
                fill: 'rgb(30,30,32)',
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </ChartWrapper>
      <SideImage backgroundImage={jewleryImage} />
    </StyledChart>
  )
}

export default Chart
