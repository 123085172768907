import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle from './Global.Styled'
import { ThemeProvider } from 'styled-components'
import getTheme from './shared-resources/theme'
import Consent from './components/Consent/Consent'
import Home from './components/Home/Home'
import Purchase from './components/Purchase/Purchase'
import WeddingRingConfigurator from './components/WeddingRingConfigurator/WeddingRingConfigurator'
import GoldAlloys from './components/GoldAlloys/GoldAlloys'
import Contact from './components/Contact/Contact'
import Imprint from './components/Imprint/Imprint'
import AGB from './components/AGB/AGB'
import Datenschutz from './components/DataProtection/DataProtection'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={getTheme()}>
        <Consent />
        <GlobalStyle />
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path="purchase" element={<Purchase />} />
            <Route path="wedding-ring-configurator" element={<WeddingRingConfigurator />} />
            <Route path="gold-alloys" element={<GoldAlloys />} />
            <Route path="contact" element={<Contact />} />
            <Route path="impressum" element={<Imprint />} />
            <Route path="agb" element={<AGB />} />
            <Route path="datenschutzbelehrung" element={<Datenschutz />} />
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  )
}

export default App
