import fetch, { METHOD } from '../fetch'

export const login = async (email, password) => {
  const { status, token } = await fetch(METHOD.POST, '/auth/login', {
    email,
    password,
  })
  if (status && token) {
    return token
  }
  return false
}

export const assignLink = async (username, password, linkUUID) => {
  const { status, token } = await fetch(METHOD.POST, '/auth/assign-link', {
    username,
    password,
    linkUUID,
  })
  if (status && token) {
    return token
  }
  return false
}

export const resolveToken = async (token) => {
  const { status, account } = await fetch(METHOD.POST, '/auth/resolve-token', {
    token,
  })
  return status ? account : false
}

export const register = async (
  token,
  firstName,
  lastName,
  company,
  position,
  email,
  phone,
  password,
  newsletter,
) => {
  const { status } = await fetch(METHOD.POST, '/auth/register', {
    token,
    firstName,
    lastName,
    company,
    position,
    email,
    phone,
    password,
    newsletter,
  })
  return status
}
